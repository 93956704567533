import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import startRequestFinancing from './startRequestFinancing';

export default (callback, offerId) => (dispatch) => {
    const dataAlert = {
        confirmText: i18next.t('Accept'),
        messages: [
            i18next.t(
                'Upon acceptance, the application will be sent and the process will begin'
            ),
        ],
        onSuccess: () => {
            dispatch(startRequestFinancing(callback, offerId));
        },
        title: i18next.t('Confirmation'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };
    dispatch(alerts.actions.show(dataAlert));
};
