import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Menu } from 'sunwise-ui';

import HeaderMenuItems from './HeaderMenuItems';

const StyledMenu = styled(Menu)(() => ({
    '& .MuiList-root': {
        'li, span': { fontSize: '14px' },
        padding: 0,
    },
}));

const HeaderMenu = ({ anchorMenu, selectedTheme, setAnchorMenu, setTheme }) => {
    const isMenuOpen = Boolean(anchorMenu);
    const handleCloseProfileMenu = () => setAnchorMenu(null);
    return (
        <StyledMenu
            anchorEl={anchorMenu}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleCloseProfileMenu}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
        >
            <HeaderMenuItems
                {...{
                    selectedTheme,
                    setTheme,
                }}
            />
        </StyledMenu>
    );
};

HeaderMenu.propTypes = {
    anchorMenu: PropTypes.object,
    selectedTheme: PropTypes.string,
    setAnchorMenu: PropTypes.func,
    setTheme: PropTypes.func,
};

export default HeaderMenu;
