import i18next from 'i18next';

export const BUREAU_STATUS = {
    REQUESTED: 'requested',
    REFUSED: 'refused',
    NOT_FOUND: 'not_found',
    NOT_AUTHORIZED: 'not_authorized',
    PASSED: 'passed',
    PENDING_CLARIFICATION: 'pending_clarification',
};

export const DOCUMENT_STATUS = {
    APPROVED_STATUS: 'Aprobado',
    INPROCESS_STATUS: 'Proceso',
    NO_DOCUMENT_STATUS: 'Sin documento',
    REJECTED_STATUS: 'Rechazado',
    REQUESTED_STATUS: 'Solicitado',
};

export const FILE_ALLOWED_EXTENSIONS = {
    CSV: '.csv,text/csv,application/vnd.ms-excel',
    IMAGE: 'image/png,image/jpeg,image/jpg',
    PDF: '.pdf,application/pdf',
    XML: '.xml,text/xml',
};

export const MAX_FILE_SIZE = 10485760;
export const MAX_SMALL_FILE_SIZE = 2097152;

export const STATUS = {
    REQUESTED_STATUS: { key: 0, value: i18next.t('Requested') }, // Solicitado
    INPROCESS_STATUS: { key: 1, value: i18next.t('Process') }, // Proceso
    REJECTED_STATUS: { key: 2, value: i18next.t('Rejected') }, // Rechazado
    APPROVED_STATUS: { key: 3, value: i18next.t('Approved') }, // Aprobado
    CLOSED_STATUS: { key: 4, value: i18next.t('Closed') }, // Cerrado
    QUOTED_STATUS: { key: 5, value: i18next.t('Quoted') }, // Cotizado
    CANCELLED_STATUS: { key: 6, value: i18next.t('Cancelled') }, // Cancelado
    PRE_APPROVED_STATUS: {
        key: 7,
        value: i18next.t('Pre approved'),
    }, // Preaprobado
    INCOMPLETE_STATUS: { key: 8, value: i18next.t('Incomplete') }, // Incompleto
    CONDITIONED_APPROVED_STATUS: {
        key: 9,
        value: i18next.t('Approved conditional'),
    }, // Aprobado condicionado
};

export const URL_TYPES = {
    LISA: 1,
    SUNWISE: 0,
};
