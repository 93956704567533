import get from 'lodash/get';

import { getInteractiveProposal } from 'common/api/interactiveProposal';

import {
    FETCH_INTERACTIVE_PROPOSAL,
    FETCH_INTERACTIVE_PROPOSAL_FAILURE,
    FETCH_INTERACTIVE_PROPOSAL_SUCCESS,
} from '../actionTypes';
import { interactiveProposalPreviewActions } from '../reducer';

import fetchInteractiveProposalSignature from './fetchInteractiveProposalSignature';

export default (id) => (dispatch) =>
    new Promise((resolve) => {
        dispatch(
            interactiveProposalPreviewActions[FETCH_INTERACTIVE_PROPOSAL]()
        );

        getInteractiveProposal(id)
            .then((response) => {
                dispatch(
                    interactiveProposalPreviewActions[
                        FETCH_INTERACTIVE_PROPOSAL_SUCCESS
                    ](response.data)
                );
                const hasApproved = get(response.data, 'has_approbed', false);
                if (hasApproved) {
                    dispatch(fetchInteractiveProposalSignature(id));
                }
            })
            .catch((error) =>
                dispatch(
                    interactiveProposalPreviewActions[
                        FETCH_INTERACTIVE_PROPOSAL_FAILURE
                    ](error?.response?.data?.errors)
                )
            )
            .finally(resolve);
    });
