import React from 'react';

import FilePresentIcon from '@mui/icons-material/FilePresent';
import SendIcon from '@mui/icons-material/Send';
import { styled } from '@mui/material/styles';
import isArray from 'lodash/isArray';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Typography } from 'sunwise-ui';

import {
    getFileExtensionByPath,
    isCompressedFileSupported,
    isImageFileSupported,
} from 'common/utils/helpers';

import { DOCUMENT_STATUS } from '../../constants';

import FileUploader from './components/FileUploader';
import FileUploaderButton from './components/FileUploaderButton';
import PreDownloadUploader from './components/PreDownloadUploader';
import * as selectors from './selectors';

const FilePreview = styled(Box)`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    width: 100%;
`;

const BoxImgContainer = styled(Box)`
    width: 100%;
    height: 300px;
    background-image: url(${(props) => props.backgroundImage});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
`;

const FormContainer = ({ handleSend, initialFile, initialValues, status }) => {
    const { t } = useTranslation();
    const { control, handleSubmit, setValue, watch } = useForm({
        defaultValues: initialValues,
    });

    const fileValue = watch('file');
    const removeFile = () => setValue('file', null);
    const handleSave = (values) =>
        handleSend(values.file && values.file[0], () => removeFile());

    const renderPreview = (
        tempUrl,
        isImage,
        isCompressedFile,
        isFileLoaded
    ) => {
        if (isImage) return <BoxImgContainer backgroundImage={tempUrl} />;
        if (isCompressedFile || isFileLoaded)
            return <FilePresentIcon sx={{ fontSize: 100 }} />;
        return (
            <embed
                height="400"
                src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${tempUrl}`}
                width="100%"
            />
        );
    };

    const finalUrl = initialFile?.includes('https')
        ? initialFile
        : `${process.env.REACT_APP_API_URL}${initialFile}`;

    const renderContent = () => {
        if (
            (status === DOCUMENT_STATUS.REQUESTED_STATUS ||
                status === DOCUMENT_STATUS.NO_DOCUMENT_STATUS) &&
            initialFile !== null &&
            !isArray(fileValue)
        )
            return (
                <PreDownloadUploader
                    control={control}
                    name="file"
                    initialFile={initialFile}
                    setValue={setValue}
                />
            );

        if (!isArray(fileValue) && initialFile === null)
            return (
                <FileUploader
                    control={control}
                    name="file"
                    setValue={setValue}
                />
            );

        if (isArray(fileValue))
            return (
                <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="column"
                    gap={2}
                    justifyContent="center"
                    p={2}
                    sx={{ width: '100%' }}
                >
                    {renderPreview(
                        fileValue[0]?.preview,
                        isImageFileSupported(fileValue[0]?.path),
                        isCompressedFileSupported(fileValue[0]?.path),
                        true
                    )}
                    <Typography variant="body2" fontWeight="bold" p={2}>
                        {t('File')}{' '}
                        {getFileExtensionByPath(
                            fileValue[0]?.path
                        ).toUpperCase()}
                    </Typography>

                    <Box display="flex" gap={2}>
                        <Button color="secondary" onClick={removeFile}>
                            {t('Cancel')}
                        </Button>
                        <Button
                            onClick={handleSubmit(handleSave)}
                            startIcon={<SendIcon />}
                        >
                            {t('Save')}
                        </Button>
                    </Box>
                </Box>
            );

        return (
            <FilePreview>
                {status !== DOCUMENT_STATUS.APPROVED_STATUS && (
                    <FileUploaderButton
                        control={control}
                        name="file"
                        setValue={setValue}
                    />
                )}
                {renderPreview(
                    finalUrl,
                    isImageFileSupported(finalUrl),
                    isCompressedFileSupported(finalUrl)
                )}
                <Typography variant="body2" fontWeight="bold" p={2}>
                    {t('File')} {getFileExtensionByPath(finalUrl).toUpperCase()}
                </Typography>
            </FilePreview>
        );
    };

    return <Box component="form">{renderContent()}</Box>;
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValues,
});

FormContainer.propTypes = {
    handleSend: PropTypes.func,
    initialFile: PropTypes.string,
    initialValues: PropTypes.object,
    status: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default connect(mapStateToProps)(FormContainer);
