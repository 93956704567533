import React, { useEffect, useState } from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, UiDialog as Dialog } from 'sunwise-ui';

import CreditBureauCard from '../../creditBureauCard';
import * as creditBureauCardActions from '../../creditBureauCard/actions';
import UploadDocument from '../../uploadDocument';
import * as actions from '../actions';
import { VIEWS } from '../constants';
import * as selectors from '../selectors';

import FinancingDetails from './FinancingDetails';
import FinancingRequest from './FinancingRequest';
import InstallerCard from './InstallerCard';
import ModalHeader from './ModalHeader';
import Wrapper from './Wrapper';

const FinancingModal = ({
    applicationDocuments,
    closeModal,
    documents,
    fetchCreditBureauData,
    fetchCreditDocuments,
    financing,
    handleClickBack,
    isFetchingCreditDocuments,
    isOpenModal,
    offerId,
    proposalData,
    setView,
    view,
}) => {
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState(false);
    const [headerProps, setHeaderProps] = useState({
        title: t('Financing'),
    });
    const [selectedFile, setSelectedFile] = useState({});
    const creditItem = get(proposalData, 'credit_item', {});
    const creditApplicationId = get(
        proposalData,
        'credit_application_id',
        null
    );
    const creditStatus = get(proposalData, 'credit_application.status', null);
    const agent = get(creditItem, 'financier_installer.agent', {});
    const hasRequestBureau = get(proposalData, 'has_request_buro', false);

    useEffect(() => {
        if (hasRequestBureau && offerId) {
            fetchCreditBureauData(offerId);
        }
    }, [hasRequestBureau]);

    useEffect(() => {
        switch (view) {
            case VIEWS.BUREAU_CREDIT:
                setHeaderProps({
                    handleClickBack: () => setView(VIEWS.FINANCING_REQUEST),
                    title: t('Credit bureau'),
                });
                break;
            case VIEWS.FINANCING_DETAILS:
                setHeaderProps({
                    handleClickBack: () => setView(VIEWS.FINANCING_REQUEST),
                    title: `${financing?.name} (${t('Proposal')})`,
                });
                break;
            case VIEWS.UPLOAD_DOCUMENT:
                setHeaderProps({
                    handleClickBack: () => setView(VIEWS.FINANCING_REQUEST),
                    title: t('Upload document'),
                });
                break;
            default:
                setHeaderProps({
                    title: t('Financing'),
                });
                break;
        }
    }, [view]);

    const handleChange = (_, isExpanded) => {
        setExpanded(isExpanded);
    };

    const handleUpdateFile = (file) => setSelectedFile(file);

    const renderContent = () => {
        switch (view) {
            case VIEWS.BUREAU_CREDIT:
                return (
                    <CreditBureauCard.Container
                        creditStatus={creditStatus}
                        handleClickBack={() => setView(VIEWS.FINANCING_REQUEST)}
                        hasRequestBureau={hasRequestBureau}
                        offerId={offerId}
                        onSuccess={() => {
                            setView(VIEWS.FINANCING_REQUEST);
                        }}
                        showInModal
                    />
                );
            case VIEWS.FINANCING_DETAILS:
                return (
                    <FinancingDetails
                        creditItem={creditItem}
                        financing={financing}
                        handleClickBack={() => setView(VIEWS.FINANCING_REQUEST)}
                        showInModal
                    />
                );
            case VIEWS.UPLOAD_DOCUMENT:
                return (
                    <UploadDocument.Container
                        creditItem={creditItem}
                        handleClickBack={() => setView(VIEWS.FINANCING_REQUEST)}
                        handleUpdateFile={handleUpdateFile}
                        onSuccessCallback={() =>
                            fetchCreditDocuments(creditApplicationId)
                        }
                        selectedFile={selectedFile}
                        showInModal
                    />
                );
            default:
                return (
                    <FinancingRequest
                        applicationDocuments={applicationDocuments}
                        documents={documents}
                        creditStatus={creditStatus}
                        financing={financing}
                        handleClickBack={handleClickBack}
                        hasRequestBureau={get(
                            proposalData,
                            'has_request_buro',
                            false
                        )}
                        isFetchingCreditDocuments={isFetchingCreditDocuments}
                        setSelectedFile={setSelectedFile}
                        setView={setView}
                        showInModal
                    />
                );
        }
    };

    const handleCloseModal = () => {
        closeModal();
        setView(VIEWS.FINANCING_REQUEST);
    };

    return (
        <Dialog
            onClose={handleCloseModal}
            onEnter={() => {
                fetchCreditDocuments(creditApplicationId);
            }}
            open={isOpenModal}
            size="md"
            title={<ModalHeader {...headerProps} />}
        >
            <Box display="flex" flexDirection="column" sx={{ height: '100%' }}>
                <Wrapper
                    sx={{
                        height: 700,
                    }}
                >
                    {renderContent()}
                </Wrapper>
                <InstallerCard
                    agent={agent}
                    expanded={expanded}
                    handleChange={handleChange}
                />
            </Box>
        </Dialog>
    );
};

FinancingModal.propTypes = {
    applicationDocuments: PropTypes.array,
    closeModal: PropTypes.func,
    documents: PropTypes.array,
    fetchCreditBureauData: PropTypes.func,
    fetchCreditDocuments: PropTypes.func,
    financing: PropTypes.object,
    handleClickBack: PropTypes.func,
    isFetchingCreditDocuments: PropTypes.bool,
    isOpenModal: PropTypes.bool,
    offerId: PropTypes.string,
    proposalData: PropTypes.object,
    setView: PropTypes.func,
    view: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
    applicationDocuments: selectors.getApplicationDocuments,
    documents: selectors.getDocuments,
    isFetchingCreditDocuments: selectors.getIsFetchingCreditDocuments,
    isOpenModal: selectors.getIsOpenModal,
    view: selectors.getSelectedView,
});

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(actions.closeModal()),
    fetchCreditBureauData: (offerId) =>
        dispatch(creditBureauCardActions.fetchBureauData(offerId)),
    fetchCreditDocuments: (creditApplicationId) =>
        dispatch(actions.fetchCreditDocuments(creditApplicationId)),
    setView: (value) => dispatch(actions.setView(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FinancingModal);
