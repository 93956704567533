import { createSlice } from '@reduxjs/toolkit';

import {
    FETCH_CREDIT_DOCUMENTS,
    FETCH_CREDIT_DOCUMENTS_FAILURE,
    FETCH_CREDIT_DOCUMENTS_SUCCESS,
    REQUEST_FINANCING,
    REQUEST_FINANCING_FAILURE,
    REQUEST_FINANCING_SUCCESS,
    RESET,
    SET_IS_OPEN_MODAL,
    SET_VIEW,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    creditDocuments: {
        isFetching: false,
        data: [],
        errors: [],
    },
    isOpenModal: false,
    requestFinancing: {
        data: null,
        errors: [],
        isStarting: false,
    },
    selectedView: 'financing_request',
};

const financingSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_CREDIT_DOCUMENTS]: (state) => {
            state.creditDocuments.isFetching = true;
        },
        [FETCH_CREDIT_DOCUMENTS_FAILURE]: (state, action) => {
            state.creditDocuments = {
                ...state.creditDocuments,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_CREDIT_DOCUMENTS_SUCCESS]: (state, action) => {
            state.creditDocuments = {
                ...state.creditDocuments,
                data: action.payload,
                isFetching: false,
            };
        },
        [REQUEST_FINANCING]: (state) => {
            state.requestFinancing = {
                ...state.requestFinancing,
                errors: [],
                isStarting: true,
            };
        },
        [REQUEST_FINANCING_FAILURE]: (state, action) => {
            state.requestFinancing = {
                ...state.requestFinancing,
                errors: action.payload,
                isStarting: false,
            };
        },
        [REQUEST_FINANCING_SUCCESS]: (state, action) => {
            state.requestFinancing = {
                ...state.requestFinancing,
                data: action.payload,
                isStarting: false,
            };
        },
        [RESET]: () => INITIAL_STATE,
        [SET_IS_OPEN_MODAL]: (state, action) => {
            state.isOpenModal = action.payload;
        },
        [SET_VIEW]: (state, action) => {
            state.selectedView = action.payload;
        },
    },
});

export const financingActions = financingSlice.actions;

export default financingSlice.reducer;
