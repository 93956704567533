import { save } from 'common/api/v1/burocredito';
import alerts from 'common/modules/alerts';
import showToast from 'common/utils/showToast';

import fetchCommercialOffer from '../../interactiveProposalPreview/actions/fetchCommercialOffer';
import { SAVE, SAVE_FAILURE, SAVE_SUCCESS } from '../actionTypes';
import { creditBureauCardActions } from '../reducer';

import fetchBureauData from './fetchBureauData';

export default (callback, offerId, values) => (dispatch) => {
    const newValues = {
        ...values,
        reference: offerId,
    };
    dispatch(creditBureauCardActions[SAVE]());

    save(newValues)
        .then((response) => {
            dispatch(creditBureauCardActions[SAVE_SUCCESS](response.data));
            dispatch(fetchBureauData(offerId));
            dispatch(fetchCommercialOffer(offerId));
            dispatch(alerts.actions.close());
            if (callback) callback();
            showToast();
        })
        .catch((error) =>
            dispatch(
                creditBureauCardActions[SAVE_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
