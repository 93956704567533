import { NAME } from './constants';

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;

export const SAVE = `${NAME}/SAVE`;
export const SAVE_FAILURE = `${NAME}/SAVE_FAILURE`;
export const SAVE_SUCCESS = `${NAME}/SAVE_SUCCESS`;

export const UPLOAD_DOCUMENT = `${NAME}/UPLOAD_DOCUMENT`;
export const UPLOAD_DOCUMENT_FAILURE = `${NAME}/UPLOAD_DOCUMENT_FAILURE`;
export const UPLOAD_DOCUMENT_SUCCESS = `${NAME}/UPLOAD_DOCUMENT_SUCCESS`;
