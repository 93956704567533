import React, { useContext, useEffect } from 'react';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { get, isEmpty, isNull } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Card, IconButton, Typography } from 'sunwise-ui';

// import UploadForm from './components/UploadForm';
import fileUploader from 'common/modules/fileUploader';
import { LoadingContext } from 'common/utils/contexts';

import * as actions from './actions';
import * as selectors from './selectors';

const Container = ({
    handleClickBack,
    handleUpdateFile,
    isUploadingFile,
    onSuccessCallback,
    selectedFile,
    showInModal = false,
    uploadDocumentFile,
}) => {
    const { t } = useTranslation();
    const loadingContext = useContext(LoadingContext);

    useEffect(() => {
        if (isEmpty(loadingContext)) return;
        if (isUploadingFile)
            loadingContext.openLoading(t('Uploading file').concat('...'));
        else loadingContext.closeLoading();
    }, [isUploadingFile]);

    const handleSend = (tempFile, onSuccess) => {
        const selectedFileId = get(selectedFile, 'id', null);
        if (!isNull(selectedFileId)) {
            uploadDocumentFile(
                handleUpdateFile,
                onSuccess,
                onSuccessCallback,
                selectedFileId,
                tempFile
            );
        }
    };

    return (
        <Card
            sx={{
                minHeight: '100%',
                mb: 0,
                ...(showInModal ? { boxShadow: 'none' } : {}),
            }}
        >
            {!showInModal && (
                <Card.Header>
                    <Box alignItems="center" display="flex" gap={2}>
                        <IconButton onClick={handleClickBack}>
                            <ChevronLeftIcon />
                        </IconButton>
                        <Typography variant="h5">
                            {t('Upload document')}
                        </Typography>
                    </Box>
                </Card.Header>
            )}
            <Card.Body>
                <fileUploader.Container
                    handleSend={handleSend}
                    initialFile={selectedFile.file}
                    status={selectedFile.status}
                />
            </Card.Body>
        </Card>
    );
};

Container.propTypes = {
    handleClickBack: PropTypes.func,
    handleUpdateFile: PropTypes.func,
    isUploadingFile: PropTypes.bool,
    onSuccessCallback: PropTypes.func,
    selectedFile: PropTypes.object,
    showInModal: PropTypes.bool,
    uploadDocumentFile: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    isUploadingFile: selectors.isFileUploading,
});

const mapDispatchToProps = (dispatch) => ({
    uploadDocumentFile: (
        handleUpdateFile,
        onSuccess,
        onSuccessCallback,
        selectedFileId,
        tempFile
    ) =>
        dispatch(
            actions.uploadDocumentFile(
                handleUpdateFile,
                onSuccess,
                onSuccessCallback,
                selectedFileId,
                tempFile
            )
        ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);
