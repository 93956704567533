import { startRequestFinancing } from 'common/api/v1/sunpay';

import {
    REQUEST_FINANCING,
    REQUEST_FINANCING_FAILURE,
    REQUEST_FINANCING_SUCCESS,
} from '../actionTypes';
import { financingActions } from '../reducer';

export default (callback, offerId) => (dispatch) => {
    dispatch(financingActions[REQUEST_FINANCING]());

    startRequestFinancing(offerId)
        .then((response) => {
            dispatch(
                financingActions[REQUEST_FINANCING_SUCCESS](response.data)
            );
            if (callback) callback();
        })
        .catch((error) => {
            dispatch(
                financingActions[REQUEST_FINANCING_FAILURE](
                    error?.response?.data?.errors
                )
            );
        });
};
