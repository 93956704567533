import React from 'react';

import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import LayoutComponent from './LayoutComponent';

const LayoutRoute = ({
    component,
    layout,
    redirect = { pathname: '/' },
    isHidden = false,
    ...rest
}) => {
    if (isHidden) return <Redirect to={redirect} />;

    return (
        <Route
            {...rest}
            render={(matchProps) => (
                <LayoutComponent
                    component={component}
                    layout={layout}
                    matchProps={matchProps}
                    rest={rest}
                />
            )}
        />
    );
};

LayoutRoute.propTypes = {
    component: PropTypes.func,
    isPublic: PropTypes.bool,
    layout: PropTypes.func,
    multiBranchMode: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
    onlyForMexico: PropTypes.bool,
    redirect: PropTypes.object,
    requiredPermissions: PropTypes.array,
    isHidden: PropTypes.bool,
};

export default LayoutRoute;
