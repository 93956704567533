import React from 'react';

import DarkModeIcon from '@mui/icons-material/DarkMode';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { MenuItem, Switch } from 'sunwise-ui';

const StyledMenuItem = styled(MenuItem)(() => ({
    display: 'flex',
    gap: '8px',
    padding: '16px',
    '& svg': {
        color: '#848bab',
        height: '24px',
        width: '24px',
    },
    '&:hover': {
        '& svg': {
            transform: 'scale(1.14)',
        },
    },
}));

const HeaderMenuItems = ({ selectedTheme, setTheme }) => {
    const { t } = useTranslation();
    const handleOnSwitchTheme = (e) => {
        const theme = e.target.checked ? 'dark' : 'light';
        setTheme(theme);
    };

    return (
        <>
            <StyledMenuItem>
                <DarkModeIcon />
                {t('Dark mode')}{' '}
                <Switch
                    checked={selectedTheme === 'dark'}
                    onChange={handleOnSwitchTheme}
                />
            </StyledMenuItem>
        </>
    );
};

HeaderMenuItems.propTypes = {
    selectedTheme: PropTypes.string,
    setTheme: PropTypes.func,
};

export default HeaderMenuItems;
