import fetchBranding from './fetchBranding';
import fetchCommercialOffer from './fetchCommercialOffer';
import fetchElectricBills from './fetchElectricBills';
import fetchInteractiveProposal from './fetchInteractiveProposal';
import fetchInteractiveProposalContent from './fetchInteractiveProposalContent';
import saveCounterVisits from './saveCounterVisits';

export default (offerId, initTemplate, countVisit = true) =>
    (dispatch) => {
        Promise.all([
            dispatch(fetchBranding(offerId)),
            dispatch(fetchCommercialOffer(offerId)),
            dispatch(fetchElectricBills(offerId)),
            dispatch(fetchInteractiveProposal(offerId)),
            dispatch(fetchInteractiveProposalContent(offerId, initTemplate)),
        ])
            .then(() => {
                if (!countVisit) return;
                dispatch(saveCounterVisits(offerId));
            })
            .catch(() => {});
    };
