import API from '../..';
const ENTITY = 'sunpay';

export const getCommercialOffer = (id) =>
    API.get(`/api/v1/${ENTITY}/sunpal-credit-application/${id}/`);
export const getCreditDocuments = (id) =>
    API.get(`/api/v1/${ENTITY}/sunpal-credit-application-documents/${id}/`);
export const startRequestFinancing = (id) =>
    API.put(`/api/v1/${ENTITY}/sunpal-credit-application/${id}/`);
export const uploadCreditDocument = (id, data) =>
    API.put(
        `/api/v1/${ENTITY}/sunpal-credit-application-documents/${id}/`,
        data
    );
