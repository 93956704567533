import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

export const getSaveDocument = createSelector(
    getModel,
    (model) => model.saveDocument
);

export const getIsSavingDocument = createSelector(
    getSaveDocument,
    (model) => model.isSaving
);

export const getSaveDocumentErrors = createSelector(
    getSaveDocument,
    (model) => model.errors
);

export const getUploadingFile = createSelector(
    getModel,
    (model) => model.fileUploaded
);

export const isFileUploading = createSelector(
    getUploadingFile,
    (fileUploaded) => fileUploaded.isUploading
);

export const getUploadedFileErrors = createSelector(
    getUploadingFile,
    (fileUploaded) => fileUploaded.errors
);
