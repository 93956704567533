import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Divider, Typography } from 'sunwise-ui';

import OpacityAnimation from 'common/components/animations/OpacityAnimation';
import { PasswordInput, UserInput } from 'common/components/form';
import ShowErrors from 'common/components/ShowErrors';
import yupResolver from 'common/utils/yupResolver';

import logotipo from 'resources/logotipoSunpal.svg';

import * as actions from '../actions';
import { getTranslatedErrorMessage } from '../helpers';
import * as selectors from '../selectors';
import validate from '../validate';

const Loginform = ({ errors, initialValues, isLoadingLogin, login }) => {
    const { t } = useTranslation();
    const [typeFieldPassword, setTypeFieldPassword] = useState('password');
    const showPassword = typeFieldPassword === 'text';

    const { control, handleSubmit, reset, watch } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    useEffect(() => reset(initialValues), [initialValues]);

    const handleClickShowPassoword = () => {
        if (showPassword) setTypeFieldPassword('password');
        else setTypeFieldPassword('text');
    };

    const handleOnSubmit = (values) => login(values);

    return (
        <Box
            display="flex"
            width="100%"
            height="100%"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
        >
            <OpacityAnimation>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'center',
                        maxWidth: '670px',
                        m: '0 auto',
                        px: 3,
                    }}
                >
                    <Box my={1} width="100%">
                        <img alt="Sunpal" src={logotipo} width="238" />
                    </Box>

                    <Box my={1} width="100%">
                        <Typography variant="h3">{t('Log in')}</Typography>
                    </Box>

                    <Divider sx={{ my: 2 }} />

                    <form onSubmit={handleSubmit(handleOnSubmit)}>
                        <UserInput
                            control={control}
                            label={t('User')}
                            name="username"
                            type="username"
                        />

                        <PasswordInput
                            control={control}
                            handleClickShowPassoword={handleClickShowPassoword}
                            label="NIP"
                            name="password"
                            showPassword={showPassword}
                            type={typeFieldPassword}
                            value={watch('password')}
                        />

                        <ShowErrors
                            errors={errors.map((error) =>
                                getTranslatedErrorMessage(error)
                            )}
                        />

                        <Button
                            disabled={isLoadingLogin}
                            fullWidth
                            type="submit"
                        >
                            {isLoadingLogin
                                ? t('Loading').concat('...')
                                : t('Log in')}
                        </Button>
                    </form>
                </Box>
            </OpacityAnimation>
        </Box>
    );
};

Loginform.propTypes = {
    errors: PropTypes.array,
    initialValues: PropTypes.object,
    isLoadingLogin: PropTypes.bool,
    login: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    errors: selectors.getLoginErrors,
    initialValues: selectors.getInitialValues,
    isLoadingLogin: selectors.getIsLoadingLogin,
});

const mapDispatchToProps = (dispatch) => ({
    login: (data, onSuccessCallback) =>
        dispatch(actions.login(data, onSuccessCallback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Loginform);
