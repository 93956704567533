import { getInteractiveProposalSignature } from 'common/api/interactiveProposal';

import {
    FETCH_INTERACTIVE_PROPOSAL_SIGNATURE,
    FETCH_INTERACTIVE_PROPOSAL_SIGNATURE_FAILURE,
    FETCH_INTERACTIVE_PROPOSAL_SIGNATURE_SUCCESS,
} from '../actionTypes';
import { interactiveProposalPreviewActions } from '../reducer';

export default (id) => (dispatch) =>
    new Promise((resolve) => {
        dispatch(
            interactiveProposalPreviewActions[
                FETCH_INTERACTIVE_PROPOSAL_SIGNATURE
            ]()
        );

        getInteractiveProposalSignature(id)
            .then((response) =>
                dispatch(
                    interactiveProposalPreviewActions[
                        FETCH_INTERACTIVE_PROPOSAL_SIGNATURE_SUCCESS
                    ](response.data)
                )
            )
            .catch((error) =>
                dispatch(
                    interactiveProposalPreviewActions[
                        FETCH_INTERACTIVE_PROPOSAL_SIGNATURE_FAILURE
                    ](error?.response?.data?.errors)
                )
            )
            .finally(resolve);
    });
