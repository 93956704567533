import { get, isNull } from 'lodash';

import { getInteractiveProposalContent } from 'common/api/interactiveProposal';

import {
    FETCH_INTERACTIVE_PROPOSAL_CONTENT,
    FETCH_INTERACTIVE_PROPOSAL_CONTENT_FAILURE,
    FETCH_INTERACTIVE_PROPOSAL_CONTENT_SUCCESS,
    FETCH_INTERACTIVE_PROPOSAL_FAILURE,
} from '../actionTypes';
import { interactiveProposalPreviewActions } from '../reducer';

export default (id, initTemplate) => (dispatch) =>
    new Promise((resolve) => {
        dispatch(
            interactiveProposalPreviewActions[
                FETCH_INTERACTIVE_PROPOSAL_CONTENT
            ]()
        );

        getInteractiveProposalContent(id)
            .then((response) => {
                const data = get(response, 'data', {});
                dispatch(
                    interactiveProposalPreviewActions[
                        FETCH_INTERACTIVE_PROPOSAL_CONTENT_SUCCESS
                    ](data)
                );
                if (isNull(data?.content_str))
                    dispatch(
                        interactiveProposalPreviewActions[
                            FETCH_INTERACTIVE_PROPOSAL_FAILURE
                        ]([{ message: 'No content found' }])
                    );
                else {
                    const documentContent = get(data, 'content_str', '{}');
                    initTemplate(JSON.parse(documentContent), id);
                }
            })
            .catch((error) =>
                dispatch(
                    interactiveProposalPreviewActions[
                        FETCH_INTERACTIVE_PROPOSAL_CONTENT_FAILURE
                    ](error?.response?.data?.errors)
                )
            )
            .finally(resolve);
    });
