import { configureStore } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import createRootReducer from './reducer';

export const history = createBrowserHistory();

export default function configureStoreApp(preloadedState) {
    const store = configureStore({
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({ serializableCheck: false }).concat(
                routerMiddleware(history)
            ),
        preloadedState,
        reducer: createRootReducer(history),
    });

    if (process.env.NODE_ENV !== 'production' && module.hot) {
        module.hot.accept('./reducer', () =>
            store.replaceReducer(createRootReducer(history))
        );
    }

    return store;
}
