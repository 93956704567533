import styled from 'styled-components';
import { Box } from 'sunwise-ui';

import { scroll } from 'common/utils/mixins';

export default styled(Box)`
    overflow-x: hidden;
    overflow-y: scroll;
    ${scroll.custom}
`;
