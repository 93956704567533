import { saveInteractiveProposalSignature } from 'common/api/interactiveProposal';

import {
    SAVE_INTERACTIVE_PROPOSAL_SIGNATURE,
    SAVE_INTERACTIVE_PROPOSAL_SIGNATURE_FAILURE,
    SAVE_INTERACTIVE_PROPOSAL_SIGNATURE_SUCCESS,
} from '../actionTypes';
import { interactiveProposalPreviewActions } from '../reducer';

import fetchInteractiveProposal from './fetchInteractiveProposal';
import fetchInteractiveProposalSignature from './fetchInteractiveProposalSignature';
import openApprovalModal from './openApprovalModal';
import toogleToolbarMessage from './toogleToolbarMessage';

export default (offerId, values) => (dispatch) => {
    dispatch(
        interactiveProposalPreviewActions[SAVE_INTERACTIVE_PROPOSAL_SIGNATURE]()
    );

    saveInteractiveProposalSignature(offerId, values)
        .then(() => {
            dispatch(
                interactiveProposalPreviewActions[
                    SAVE_INTERACTIVE_PROPOSAL_SIGNATURE_SUCCESS
                ]()
            );
            dispatch(toogleToolbarMessage(true));
            dispatch(openApprovalModal(false));
            dispatch(fetchInteractiveProposal(offerId));
            dispatch(fetchInteractiveProposalSignature(offerId));
        })
        .catch((error) =>
            dispatch(
                interactiveProposalPreviewActions[
                    SAVE_INTERACTIVE_PROPOSAL_SIGNATURE_FAILURE
                ](error?.response?.data?.errors)
            )
        );
};
