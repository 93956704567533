import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const LayoutComponent = ({
    component: Component,
    layout: Layout,
    matchProps,
    rest,
}) => {
    return (
        <Layout {...rest}>
            <Component {...matchProps} />
        </Layout>
    );
};

LayoutComponent.propTypes = {
    component: PropTypes.func,
    layout: PropTypes.func,
    matchProps: PropTypes.object,
    rest: PropTypes.object,
};

export default connect(null, null)(LayoutComponent);
