import React from 'react';

import PersonIcon from '@mui/icons-material/Person';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Box, FormControl, FormHelperText, TextField } from 'sunwise-ui';

const UserInput = ({ control, icon = null, label, name, type }) => (
    <Controller
        control={control}
        name={name}
        render={({ field, fieldState: { error } }) => (
            <FormControl error={Boolean(error)} fullWidth>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        gap: '0.75rem',
                    }}
                >
                    <Box>{icon || <PersonIcon fontSize="small" />}</Box>
                    <TextField
                        fullWidth
                        label={label}
                        name={name}
                        type={type}
                        variant="standard"
                        {...field}
                    />
                </Box>

                {Boolean(error) && (
                    <FormHelperText>{error?.message}</FormHelperText>
                )}
            </FormControl>
        )}
    />
);

UserInput.propTypes = {
    control: PropTypes.object,
    icon: PropTypes.element,
    label: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
};

export default UserInput;
