import i18next from 'i18next';

export default () => ({
    EMAIL_VALIDATION_TEXT: i18next.t('Enter a valid email'),
    INVALID_NIP_TEXT: i18next.t('Invalid NIP or data has expired'),
    REQUIRED_TEXT: i18next.t('This field is required'),
    getNumberDigitsText: (digits = '') =>
        i18next.t('The field must contain {{digits}} digits', {
            digits: digits,
        }),
    minimumNumberCharacters: (number = '') =>
        i18next.t('Minimum {{number}} characters', { number }),
});
