import { approveInteractiveProposal } from 'common/api/interactiveProposal';

import {
    APPROVE_INTERACTIVE_PROPOSAL,
    APPROVE_INTERACTIVE_PROPOSAL_FAILURE,
    APPROVE_INTERACTIVE_PROPOSAL_SUCCESS,
} from '../actionTypes';
import { interactiveProposalPreviewActions } from '../reducer';

import fetchInteractiveProposal from './fetchInteractiveProposal';
import fetchInteractiveProposalSignature from './fetchInteractiveProposalSignature';
import openApprovalModal from './openApprovalModal';

export default (values, onSuccesCallback) => (dispatch) => {
    dispatch(interactiveProposalPreviewActions[APPROVE_INTERACTIVE_PROPOSAL]());

    const newValues = {
        email: values.email,
        proposal_ref_id: values.offerId,
    };

    approveInteractiveProposal(newValues)
        .then(() => {
            dispatch(
                interactiveProposalPreviewActions[
                    APPROVE_INTERACTIVE_PROPOSAL_SUCCESS
                ]()
            );
            dispatch(openApprovalModal(false));
            dispatch(fetchInteractiveProposal(values.offerId));
            dispatch(fetchInteractiveProposalSignature(values.offerId));
            if (onSuccesCallback) onSuccesCallback();
        })
        .catch((error) =>
            dispatch(
                interactiveProposalPreviewActions[
                    APPROVE_INTERACTIVE_PROPOSAL_FAILURE
                ](error?.response?.data?.errors)
            )
        );
};
