import i18next from 'i18next';

import { login } from 'common/api/v1/accounts';

import { LOGIN, LOGIN_FAILURE, LOGIN_SUCCESS } from '../actionTypes';
import { BUREAU_STEPS } from '../constants';
import { creditBureauActions } from '../reducer';

import initializeTermsForm from './initializeTermsForm';
import setCurrentStep from './setCurrentStep';

export default (data) => (dispatch) => {
    dispatch(creditBureauActions[LOGIN]());

    login(data)
        .then((response) => {
            if (!response.data || !response.data.token) {
                dispatch(
                    creditBureauActions[LOGIN_FAILURE]([
                        i18next.t(
                            'An error occurred during login. Please try later'
                        ),
                    ])
                );
                return;
            }

            dispatch(
                creditBureauActions[LOGIN_SUCCESS]({
                    ...response.data,
                    nip: data.password,
                })
            );
            dispatch(initializeTermsForm({ currentNip: data.password }));
            dispatch(setCurrentStep(BUREAU_STEPS.TERMS_AND_CONDITIONS));
        })
        .catch((error) =>
            dispatch(
                creditBureauActions[LOGIN_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
