import { createSlice } from '@reduxjs/toolkit';

import {
    AUTH,
    AUTH_FAILURE,
    AUTH_SUCCESS,
    INITIALIZE_TERMS_FORM,
    LOGIN,
    LOGIN_FAILURE,
    LOGIN_SUCCESS,
    RESET,
    RESET_TERMS_FORM,
    RESET_AUTH_FORM,
    RESET_LOGIN_FORM,
    SET_STEP,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    authorization: {
        data: {},
        errors: [],
        isLoading: false,
    },
    initialValues: {
        username: '',
        password: '',
    },
    initialValuesAuthorizeForm: {
        authorize: false,
        nip: '',
    },
    initialValuesTermsForm: {
        currentNip: '',
        accept_terms: false,
        nip: '',
    },
    login: {
        data: {},
        errors: [],
        isLoading: false,
    },
    step: 'LOGIN',
};

const creditBureauSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [AUTH]: (state) => {
            state.authorization.isLoading = true;
        },
        [AUTH_FAILURE]: (state, action) => {
            state.authorization = {
                ...state.authorization,
                errors: action.payload,
                isLoading: false,
            };
        },
        [AUTH_SUCCESS]: (state, action) => {
            state.authorization = {
                ...state.authorization,
                data: action.payload,
                isLoading: false,
            };
        },
        [LOGIN]: (state) => {
            state.login.isLoading = true;
        },
        [LOGIN_FAILURE]: (state, action) => {
            state.login = {
                ...state.login,
                errors: action.payload,
                isLoading: false,
            };
        },
        [LOGIN_SUCCESS]: (state, action) => {
            state.login = {
                ...state.login,
                data: action.payload,
                isLoading: false,
            };
        },
        [INITIALIZE_TERMS_FORM]: (state, action) => {
            state.initialValuesTermsForm = {
                ...state.initialValuesTermsForm,
                ...action.payload,
            };
        },
        [RESET]: () => INITIAL_STATE,
        [RESET_AUTH_FORM]: (state) => {
            state.initialValuesAuthorizeForm =
                INITIAL_STATE.initialValuesAuthorizeForm;
        },
        [RESET_LOGIN_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
        },
        [RESET_TERMS_FORM]: (state) => {
            state.initialValuesTermsForm = INITIAL_STATE.initialValuesTermsForm;
        },
        [SET_STEP]: (state, action) => {
            state.step = action.payload;
        },
    },
});

export const creditBureauActions = creditBureauSlice.actions;

export default creditBureauSlice.reducer;
