import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Box } from 'sunwise-ui';

import alerts from 'common/modules/alerts';

import Footer from './Footer';

const Wrapper = styled(Box)(
    ({ theme }) => `
    display: flex;
    flex-direction: column;
    height: calc(100vh - 53px);
    background-color: ${
        theme.palette.mode === 'dark'
            ? 'rgba(0, 0, 0, 0.35)'
            : 'rgb(248, 252, 255)'
    };
`
);

const DefaultLayout = ({ children }) => (
    <>
        <Wrapper>{children}</Wrapper>
        <Footer />
        <alerts.Container />
    </>
);

DefaultLayout.propTypes = { children: PropTypes.object };

export default DefaultLayout;
