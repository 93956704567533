import React, { useState } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTheme, styled } from '@mui/material/styles';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    Skeleton,
    Typography,
} from 'sunwise-ui';

import { media } from 'common/utils/mixins';

import ChipProposalStatus from './ChipProposalStatus';
import CollapsedBreadcrumbs from './CollapsedBreadcrumbs';
import CompanyLogo from './CompanyLogo';
import HeaderMenu from './HeaderMenu';

const Separator = styled(Box)`
    width: 4px;
    height: 40px;
    mix-blend-mode: normal;
    border-radius: 2px;
    ${media.md`
        height: 60px;
    `}
`;

const ProposalResume = ({
    branding,
    handleClickAlliance,
    handleClickOpenApprovalModal,
    hasApproved,
    hasFinancingRequest,
    interactiveProposalData,
    isFinancingMode,
    isLoading,
    selectedTheme,
    setTheme,
}) => {
    const { t } = useTranslation();
    const [anchorMenu, setAnchorMenu] = useState(null);
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';

    if (isEmpty(interactiveProposalData)) {
        return null;
    }

    const handleProfileMenuOpen = (event) => setAnchorMenu(event.currentTarget);

    const proposalName = get(interactiveProposalData, 'proposal_name', '');

    return (
        <Box sx={{ backgroundColor: isDarkMode ? '#000' : '#fff' }}>
            <Grid
                container
                alignItems="center"
                justifyContent="center"
                sx={{ py: { xs: 2, md: 2 } }}
            >
                <Grid
                    item
                    xs={3}
                    md={2}
                    sx={{
                        display: { md: 'flex', xs: 'none' },
                        justifyContent: 'end',
                        alignItems: 'center',
                    }}
                >
                    <CompanyLogo
                        branding={branding}
                        companyLogo={get(
                            interactiveProposalData,
                            'compay_logo_url',
                            null
                        )}
                    />
                </Grid>
                <Grid
                    item
                    xs={17}
                    md={15}
                    sx={{ display: 'flex', alignItems: 'center', gap: '24px' }}
                >
                    <Separator sx={{ backgroundColor: 'primary.main' }} />
                    <Box
                        display="flex"
                        flexDirection="column"
                        sx={{ width: '100%' }}
                    >
                        <Grid alignItems="center" container>
                            <Grid
                                item
                                xs={hasApproved ? 11 : 18}
                                lg={hasApproved ? 15 : 18}
                            >
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    gap={1}
                                >
                                    {isLoading ? (
                                        <Skeleton
                                            variant="text"
                                            sx={{ fontSize: '1rem' }}
                                        />
                                    ) : (
                                        <Typography
                                            variant="h4"
                                            sx={{
                                                fontSize: {
                                                    xs: '1rem',
                                                    md: '1.5rem',
                                                },
                                            }}
                                        >
                                            {proposalName}
                                        </Typography>
                                    )}
                                    <CollapsedBreadcrumbs
                                        interactiveProposalData={
                                            interactiveProposalData
                                        }
                                    />
                                </Box>
                            </Grid>
                            {hasApproved && (
                                <Grid
                                    item
                                    xs={6}
                                    lg={3}
                                    textAlign="center"
                                    sx={{
                                        display: { md: 'block', xs: 'none' },
                                    }}
                                >
                                    <ChipProposalStatus
                                        icon={
                                            <CheckCircleIcon
                                                sx={{
                                                    color: '#3caf5f !important',
                                                }}
                                            />
                                        }
                                        label={hasApproved ? t('Approved') : ''}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                    <Box>
                        <IconButton onClick={handleProfileMenuOpen}>
                            <MoreVertIcon />
                        </IconButton>
                        <HeaderMenu
                            anchorMenu={anchorMenu}
                            selectedTheme={selectedTheme}
                            setAnchorMenu={setAnchorMenu}
                            setTheme={setTheme}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Divider sx={{ mb: 0 }} />
            <Box px={2} py={1} sx={{ display: { md: 'none', xs: 'block' } }}>
                <Button
                    fullWidth
                    onClick={() => handleClickAlliance()}
                    sx={{ textTransform: 'none' }}
                    type="button"
                    visible={hasApproved && !isFinancingMode}
                >
                    {hasFinancingRequest
                        ? t('Go to financing')
                        : t('Start request')}
                </Button>
                <Button
                    fullWidth
                    onClick={() => handleClickOpenApprovalModal()}
                    size="small"
                    variant="outlined"
                    visible={!hasApproved}
                >
                    {t('Approve')}
                </Button>
            </Box>
        </Box>
    );
};

ProposalResume.propTypes = {
    branding: PropTypes.object,
    handleClickAlliance: PropTypes.func,
    handleClickOpenApprovalModal: PropTypes.func,
    hasApproved: PropTypes.bool,
    hasFinancingRequest: PropTypes.bool,
    interactiveProposalData: PropTypes.object,
    isFinancingMode: PropTypes.bool,
    isLoading: PropTypes.bool,
    selectedTheme: PropTypes.string,
    setTheme: PropTypes.func,
};

export default ProposalResume;
