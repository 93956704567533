import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

/* Login form */
export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

/* Terms and conditions form */
export const getTermsInitialValues = createSelector(
    getModel,
    (model) => model.initialValuesTermsForm
);

/* Authorize form */
export const getAuthorizeFormInitialValues = createSelector(
    getModel,
    (model) => model.initialValuesAuthorizeForm
);

/* Login */
export const getLogin = createSelector(getModel, (model) => model.login);

export const getLoginData = createSelector(getLogin, (model) => model.data);

export const getIsLoadingLogin = createSelector(
    getLogin,
    (model) => model.isLoading
);

export const getLoginErrors = createSelector(getLogin, (model) => model.errors);

/* Authorization */
export const getAuth = createSelector(getModel, (model) => model.authorization);

export const getAuthData = createSelector(getAuth, (model) => model.data);

export const getAuthisLoading = createSelector(
    getAuth,
    (model) => model.isLoading
);

export const getAuthErrors = createSelector(getAuth, (model) => model.errors);

/* Bureau Step */
export const getCurrentStep = createSelector(getModel, (model) => model.step);
