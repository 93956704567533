import React, { useEffect, useState } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
    Alert,
    Box,
    Card,
    Chip,
    IconButton,
    TextField,
    Typography,
} from 'sunwise-ui';

import { STATUS } from 'common/constants';

import CreditBureauForm from './components/CreditBureauForm';
import {
    getIsShowEditButton,
    getStatusButtonProps,
    getStatusLabel,
} from './helpers';
import * as selectors from './selectors';

const Container = ({
    bureauData,
    creditStatus,
    handleClickBack,
    hasRequestBureau,
    isFetching,
    offerId,
    onSuccess,
    showInModal = false,
}) => {
    const { t } = useTranslation();
    const [isEnabledEdition, setIsEnabledEdition] = useState(false);

    useEffect(() => {
        setIsEnabledEdition(false);
        if (!hasRequestBureau) {
            setIsEnabledEdition(true);
        }
    }, [hasRequestBureau]);

    const records = get(bureauData, 'records', { current: 0, limit: 1 });
    const status = get(bureauData, 'status', { key: null });
    const hasAuthorization = get(
        bureauData,
        'has_authorized_consultation',
        false
    );

    return (
        <Card sx={{ mb: 0, ...(showInModal ? { boxShadow: 'none' } : {}) }}>
            {!showInModal && (
                <Card.Header>
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            gap: 2,
                        }}
                    >
                        <IconButton onClick={handleClickBack}>
                            <ChevronLeftIcon />
                        </IconButton>
                        <Typography
                            variant="h5"
                            className="__userguiding_credit_bureau_card"
                        >
                            {t('Credit bureau')}
                        </Typography>
                    </Box>
                </Card.Header>
            )}
            <Card.Body
                sx={{
                    opacity:
                        creditStatus === STATUS.CANCELLED_STATUS.key ? 0.6 : 1,
                }}
            >
                <Box alignItems="center" display="flex" mb={2} gap={1}>
                    {records && hasRequestBureau && (
                        <>
                            <InfoIcon sx={{ color: '#637381' }} />
                            <Typography fontWeight="bold" variant="body2">
                                {records.current}/{records.limit}{' '}
                                {t('Tries').toLowerCase()}
                            </Typography>
                        </>
                    )}
                    {getIsShowEditButton(
                        creditStatus,
                        bureauData,
                        hasRequestBureau,
                        status
                    ) && (
                        <IconButton
                            onClick={() =>
                                setIsEnabledEdition(!isEnabledEdition)
                            }
                            size="small"
                            sx={{ ml: 'auto' }}
                        >
                            {isEnabledEdition ? (
                                <CloseIcon fontSize="small" />
                            ) : (
                                <EditIcon fontSize="small" />
                            )}
                        </IconButton>
                    )}
                </Box>
                {isEnabledEdition ? (
                    <CreditBureauForm
                        bureauData={bureauData}
                        creditStatus={creditStatus}
                        isFetching={isFetching}
                        offerId={offerId}
                        onSuccess={onSuccess}
                        setIsEnabledEdition={setIsEnabledEdition}
                        status={status}
                    />
                ) : (
                    <Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                mb: 2,
                            }}
                        >
                            <CheckCircleIcon
                                fontSize="small"
                                sx={{ color: '#26f2ad' }}
                            />
                            <Typography variant="body2">
                                {t('Request sent to')}
                            </Typography>
                        </Box>
                        <Box mb={2}>
                            {bureauData?.email && (
                                <TextField
                                    fullWidth
                                    disabled
                                    label={t('Email')}
                                    variant="outlined"
                                    value={bureauData?.email}
                                />
                            )}
                        </Box>
                        <Chip
                            className="__userguiding_credit_bureau_chip_status"
                            label={getStatusLabel(status)}
                            sx={{
                                width: '100%',
                                fontWeight: 600,
                                ...getStatusButtonProps(status),
                            }}
                        />
                        {!hasAuthorization && (
                            <Box my={2}>
                                <Alert severity="info">
                                    <Typography
                                        variant="caption"
                                        fontWeight="bold"
                                    >
                                        {t(
                                            'Remember to authorize the consultation by mail'
                                        )}
                                    </Typography>
                                </Alert>
                            </Box>
                        )}
                    </Box>
                )}
            </Card.Body>
        </Card>
    );
};

const mapStateToProps = createStructuredSelector({
    bureauData: selectors.getFetchData,
    isFetching: selectors.getIsFetching,
});

Container.propTypes = {
    bureauData: PropTypes.object,
    creditStatus: PropTypes.number,
    handleClickBack: PropTypes.func,
    hasRequestBureau: PropTypes.bool,
    isFetching: PropTypes.bool,
    offerId: PropTypes.string,
    onSuccess: PropTypes.func,
    showInModal: PropTypes.bool,
};

export default connect(mapStateToProps, null)(Container);
