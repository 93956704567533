import { object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default () => {
    const { REQUIRED_TEXT, minimumNumberCharacters } = getValidationTexts();

    return object().shape({
        username: string().required(REQUIRED_TEXT).nullable(),
        password: string()
            .min(7, minimumNumberCharacters(7))
            .required(REQUIRED_TEXT)
            .nullable(),
    });
};
