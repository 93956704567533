import React, { useCallback } from 'react';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import { styled } from '@mui/material/styles';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { Controller } from 'react-hook-form';
import { Button } from 'sunwise-ui';

import { MAX_FILE_SIZE } from 'common/constants';
import showToast from 'common/utils/showToast';

import { ACCEPTED_FILES } from '../constants';

const UploadMask = styled('div')`
    align-items: center;
    background-color: ${({ opacity }) => `rgba(0, 0, 0, ${opacity})`};
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 300px;
    justify-content: center;
    margin: 0;
    position: relative;
    width: 100%;

    &:after {
        border-radius: 3px 0 0 3px;
        border: 5px dashed #a3a6b4;
        bottom: 40px;
        content: '';
        display: block;
        left: 40px;
        position: absolute;
        right: 40px;
        top: 40px;
    }
`;

const FileUploader = ({
    control,
    maxSize = MAX_FILE_SIZE,
    maxSizeErrorMessage = i18next.t(
        'The file exceeds the allowable size limit'
    ),
    name,
    opacity = '0.81',
    setValue,
}) => {
    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles.length > 0 && acceptedFiles[0].size >= maxSize) {
            showToast({
                body: maxSizeErrorMessage,
                type: 'danger',
                autoClose: 3000,
            });
            return;
        }
        setValue(
            name,
            acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                })
            )
        );
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
        <Controller
            control={control}
            name={name}
            render={() => (
                <UploadMask {...getRootProps()} opacity={opacity}>
                    <input {...getInputProps()} accept={ACCEPTED_FILES} />
                    <Button
                        startIcon={<AttachFileIcon />}
                        type="button"
                        variant="text"
                    >
                        {i18next.t('Attach document')}
                    </Button>
                </UploadMask>
            )}
        />
    );
};

FileUploader.propTypes = {
    control: PropTypes.object,
    maxSize: PropTypes.number,
    maxSizeErrorMessage: PropTypes.string,
    name: PropTypes.string,
    opacity: PropTypes.string,
    setValue: PropTypes.func,
};

export default FileUploader;
