import i18next from 'i18next';
import isEmpty from 'lodash/isEmpty';

import { STATUS } from 'common/constants';
import { DEFAULT_VALUES } from 'common/constants/themeSettings';

const dataImageRegex =
    /^\s*data:([a-z]+\/[a-z]+(;[a-z-]+=[a-z-]+)?)?(;base64)?,[a-z0-9!$&',()*+,;=\-._~:@/?%\s]*\s*$/i;

const getPlaceholderImg = (size = 100, text = i18next.t('No image')) =>
    `https://placehold.jp/${size}x${size}.png?text=${text}`;

const urlRegex =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

const validateRGB = (key, values) => {
    const value = values[key];
    if (!value) return DEFAULT_VALUES[key];
    if (value[0] !== '#') return `#${value}`;
    return value;
};

export const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const addDefaultSrc = (e, size) =>
    (e.target.src = getPlaceholderImg(size));

export const arraytoDictionary = (array, key) => {
    if (!Array.isArray(array)) return {};

    return array.reduce(
        (acc, current) => ({ ...acc, [current[key]]: current }),
        {}
    );
};

export const fullNameBuild = ({ firstName, lastName, secondSurname }) => {
    let fullName = '';
    if (firstName) {
        fullName += firstName;
    }
    if (lastName) {
        if (fullName.length > 0) fullName += ' ';
        fullName += lastName;
    }
    if (secondSurname) {
        if (fullName.length > 0) fullName += ' ';
        fullName += secondSurname;
    }
    return fullName;
};

export const getColorButtonByStatus = (status) => {
    switch (status) {
        case STATUS.REQUESTED_STATUS.key:
            return '#fc983a';
        case STATUS.INCOMPLETE_STATUS.key:
            return '#848bab';
        case STATUS.CANCELLED_STATUS.key:
            return '#d24649';
        case STATUS.REJECTED_STATUS.key:
            return '#d24649';
        case STATUS.INPROCESS_STATUS.key:
            return '#1a5459';
        case STATUS.PRE_APPROVED_STATUS.key:
            return '#4ec09c';
        case STATUS.APPROVED_STATUS.key:
        case STATUS.CONDITIONED_APPROVED_STATUS.key:
            return '#54c461';
        case STATUS.CLOSED_STATUS.key:
            return '#373fb0';
        default:
            return '#011e2e';
    }
};

export const getFileExtensionByPath = (url) => {
    if (!url) return '';
    if (url.indexOf('.', url.lastIndexOf('/') + 1) === -1) return '';
    return url.split(/[#?]/)[0].split('.').pop().trim();
};

export const handleBuildTheme = ({
    baseDarkPalette,
    baseLightPalette,
    branding,
    defaultValues = DEFAULT_VALUES,
}) => {
    if (!branding || isEmpty(branding))
        return { darkPalette: baseDarkPalette, lightPalette: baseLightPalette };
    const values = { ...defaultValues, ...branding };
    const _lightPalette = {
        primary: {
            contrastText: validateRGB(
                'light_primary_contrast_text_color',
                values
            ),
            main: validateRGB('light_primary_color', values),
        },
        secondary: {
            contrastText: validateRGB(
                'light_secondary_contrast_text_color',
                values
            ),
            main: validateRGB('light_secondary_color', values),
        },
    };

    const _darkPalette = {
        primary: {
            contrastText: validateRGB(
                'dark_primary_contrast_text_color',
                values
            ),
            main: validateRGB('dark_primary_color', values),
        },
        secondary: {
            contrastText: validateRGB(
                'dark_secondary_contrast_text_color',
                values
            ),
            main: validateRGB('dark_secondary_color', values),
        },
    };

    return {
        darkPalette: { ...baseDarkPalette, ..._darkPalette },
        lightPalette: { ...baseLightPalette, ..._lightPalette },
    };
};

export const handleFileURL = (url, preffix) => {
    const pattern = /^((https):\/\/)/;
    return pattern.test(url) ? url : `${preffix}${url}`;
};

export const isCompressedFileSupported = (pathName) =>
    ['zip', 'rar'].includes(getFileExtensionByPath(pathName).toLowerCase());

export const isImageBase64 = (string) => string.match(dataImageRegex);

export const isImageFileSupported = (pathName) =>
    ['png', 'jpeg', 'jpg'].includes(
        getFileExtensionByPath(pathName).toLowerCase()
    );

export const isImageUrl = (string) => string.match(urlRegex);

export const getTranslatedStatusValue = (status) => {
    const findedStatus = Object.values(STATUS).find((s) => s.key === status);
    return findedStatus ? findedStatus.value : 'N/D';
};

const stringToColor = (string) => {
    let hash = 0;
    let i;
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
};

export const stringAvatar = (name) => {
    const nameArray = name.split(' ');
    let newName = '';
    if (nameArray.length === 1) newName = nameArray[0][0];
    else if (nameArray.length >= 2 && nameArray[1][0])
        newName = `${nameArray[0][0]}${nameArray[1][0]}`;
    else newName = nameArray[0][0];
    return {
        sx: { bgcolor: stringToColor(name) },
        children: newName?.toUpperCase(),
    };
};
