import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';

import ApprovalCard from './ApprovalCard';
import DataSheetsCard from './DataSheetsCard';
import DownloadCard from './DownloadCard';
import ElectricBillCard from './ElectricBillCard';
import FinancialCard from './FinancialCard';
import ProposalCard from './ProposalCard';
import SmartDocumentsCard from './SmartDocumentsCard';

const SupportMenuContent = ({
    datasheets,
    downloadFormat,
    electricBills,
    handleClickAlliance,
    handleClickDatasheetItem,
    handleClickDocumentItem,
    handleClickDownload,
    handleClickElectricBillItem,
    handleClickOpenApprovalModal,
    hasApproved,
    hasFinancingRequest,
    hasSignature,
    interactiveProposalData,
    interactiveProposalSignatureData,
    isFetching,
    isFinancingMode,
    proposalId,
    selectedDocument,
    setDownloadFormat,
    smartDocuments,
}) => {
    const isDisabled = isFetching;

    return (
        <>
            <ApprovalCard
                handleClickOpenApprovalModal={handleClickOpenApprovalModal}
                hasApproved={hasApproved}
                hasSignature={hasSignature}
                interactiveProposalSignatureData={
                    interactiveProposalSignatureData
                }
            />
            <DownloadCard
                downloadFormat={downloadFormat}
                isFinancingMode={isFinancingMode}
                handleClickDownload={handleClickDownload}
                selectedDocument={selectedDocument}
                setDownloadFormat={setDownloadFormat}
            />
            <ProposalCard
                handleClickDocumentItem={handleClickDocumentItem}
                isDisabled={isDisabled}
                proposalId={proposalId}
                proposalName={get(interactiveProposalData, 'proposal_name', '')}
                selectedDocumentId={get(selectedDocument, 'id', null)}
            />
            <ElectricBillCard
                electricBills={electricBills}
                handleClickElectricBillItem={handleClickElectricBillItem}
                isDisabled={isDisabled}
                selectedDocumentId={get(selectedDocument, 'id', null)}
            />
            <FinancialCard
                financing={get(interactiveProposalData, 'financing', {})}
                isFinancingMode={isFinancingMode}
                handleClickAlliance={handleClickAlliance}
                handleClickDatasheetItem={handleClickDatasheetItem}
                handleClickDocumentItem={handleClickDocumentItem}
                hasApproved={hasApproved}
                hasFinancingRequest={hasFinancingRequest}
                isDisabled={isDisabled}
                selectedDocumentId={get(selectedDocument, 'id', null)}
            />
            <SmartDocumentsCard
                handleClickDocumentItem={handleClickDocumentItem}
                isDisabled={isDisabled}
                selectedDocumentId={get(selectedDocument, 'id', null)}
                smartDocuments={smartDocuments}
            />
            <DataSheetsCard
                datasheets={datasheets}
                handleClickDatasheetItem={handleClickDatasheetItem}
                isDisabled={isDisabled}
                selectedDocumentId={get(selectedDocument, 'id', null)}
            />
        </>
    );
};

SupportMenuContent.propTypes = {
    datasheets: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    downloadFormat: PropTypes.number,
    electricBills: PropTypes.array,
    handleClickAlliance: PropTypes.func,
    handleClickDatasheetItem: PropTypes.func,
    handleClickDocumentItem: PropTypes.func,
    handleClickDownload: PropTypes.func,
    handleClickElectricBillItem: PropTypes.func,
    handleClickOpenApprovalModal: PropTypes.func,
    hasApproved: PropTypes.bool,
    hasFinancingRequest: PropTypes.bool,
    hasSignature: PropTypes.bool,
    interactiveProposalData: PropTypes.object,
    interactiveProposalSignatureData: PropTypes.object,
    isFetching: PropTypes.bool,
    isFinancingMode: PropTypes.bool,
    proposalId: PropTypes.string,
    selectedDocument: PropTypes.object,
    setDownloadFormat: PropTypes.func,
    smartDocuments: PropTypes.array,
};

export default SupportMenuContent;
