import get from 'lodash/get';

import { getElectricBills } from 'common/api/interactiveProposal';

import {
    FETCH_ELECTRIC_BILLS,
    FETCH_ELECTRIC_BILLS_FAILURE,
    FETCH_ELECTRIC_BILLS_SUCCESS,
} from '../actionTypes';
import { getElectricBillPerMonths } from '../helpers';
import { interactiveProposalPreviewActions } from '../reducer';

export default (id) => (dispatch) =>
    new Promise((resolve) => {
        dispatch(interactiveProposalPreviewActions[FETCH_ELECTRIC_BILLS]());

        getElectricBills(id)
            .then((response) => {
                const data = get(response, 'data.data', []);
                dispatch(
                    interactiveProposalPreviewActions[
                        FETCH_ELECTRIC_BILLS_SUCCESS
                    ](getElectricBillPerMonths({ lines: data }))
                );
            })
            .catch((error) =>
                dispatch(
                    interactiveProposalPreviewActions[
                        FETCH_ELECTRIC_BILLS_FAILURE
                    ](error?.response?.data?.errors)
                )
            )
            .finally(resolve);
    });
