import React from 'react';

import { styled } from '@mui/material/styles';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Box, Grid, Typography } from 'sunwise-ui';

import { ReactComponent as InteractiveNotFound } from 'resources/interactiveNotFound.svg';

const Container = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100vh - 40px);
    width: 100%;
`;

const NotFound = ({ isLoading }) => {
    const { t } = useTranslation();

    if (isLoading) return null;

    return (
        <Container>
            <Grid container justifyContent="center">
                <Grid item xs={18} md={12} textAlign="center">
                    <InteractiveNotFound />
                </Grid>
            </Grid>
            <Grid container mt={2}>
                <Grid
                    item
                    xs={18}
                    textAlign="center"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '24px',
                    }}
                >
                    <Typography
                        variant="h4"
                        fontWeight="bold"
                        sx={{ color: '#3a647f', order: { xs: 2, md: 1 } }}
                    >
                        {t('Oops! Contact your Installer!')}
                    </Typography>
                    <Typography
                        variant="body2"
                        fontWeight="bold"
                        sx={{ order: { xs: 1, md: 2 } }}
                    >
                        {t(
                            'It appears that this proposal is no longer in effect or has been deleted'
                        )}
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    );
};

NotFound.propTypes = {
    isLoading: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => ({
    redirect: (link) => dispatch(push(link)),
});

export default connect(null, mapDispatchToProps)(NotFound);
