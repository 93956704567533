import React from 'react';

import DownloadIcon from '@mui/icons-material/Download';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Box,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
} from 'sunwise-ui';

import { getDownloadOptions } from '../helpers';

import SideMenuCard from './SideMenuCard';

const DownloadCard = ({
    downloadFormat,
    handleClickDownload,
    isFinancingMode,
    selectedDocument,
    setDownloadFormat,
}) => {
    const { t } = useTranslation();

    const handleChange = (event) => {
        localStorage.setItem('downloadFormat', event.target.value);
        setDownloadFormat(event.target.value);
        event.preventDefault();
    };

    const options = getDownloadOptions(selectedDocument);

    return (
        <SideMenuCard
            icon={<DownloadIcon sx={{ color: 'primary.main' }} />}
            title={t('Download')}
        >
            <Box sx={{ alignItems: 'center', display: 'flex', gap: 1 }}>
                <FormControl fullWidth>
                    <InputLabel>
                        {t('Download format', { context: 'noun' })}
                    </InputLabel>

                    <Select
                        disabled={isFinancingMode}
                        onChange={handleChange}
                        value={downloadFormat}
                        variant="standard"
                        sx={{ fontSize: '0.875rem' }}
                    >
                        {options.map((option) => (
                            <MenuItem
                                disabled={option.disabled}
                                key={`download-item-${option.value}`}
                                value={option.value}
                            >
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <IconButton
                    color="primary"
                    disabled={!downloadFormat || isFinancingMode}
                    onClick={() => handleClickDownload()}
                    variant="default"
                >
                    <DownloadIcon />
                </IconButton>
            </Box>
        </SideMenuCard>
    );
};

DownloadCard.propTypes = {
    downloadFormat: PropTypes.number,
    handleClickDownload: PropTypes.func,
    isFinancingMode: PropTypes.bool,
    selectedDocument: PropTypes.object,
    setDownloadFormat: PropTypes.func,
};

export default DownloadCard;
