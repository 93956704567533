import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { Box, Button, Divider, Typography } from 'sunwise-ui';

import OpacityAnimation from 'common/components/animations/OpacityAnimation';
import PasswordInput from 'common/components/form/PasswordInput';
import ReactHookFormCheck from 'common/components/form/rhf/ReactHookFormCheck';
import ShowErrors from 'common/components/ShowErrors';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import { getTranslatedErrorMessage } from '../helpers';
import * as selectors from '../selectors';
import validateAuthorize from '../validateAuthorize';

const Wrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        background: transparent;
        height: 7px;
        width: 7px;
    }
    &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.15);
        border-radius: 2px;
    }
`;

const AuthorizeForm = ({
    authErrors,
    handleAuth,
    initialValues,
    prepareReject,
    token,
}) => {
    const { t } = useTranslation();
    const [typeFieldPassword, setTypeFieldPassword] = useState('password');
    const showPassword = typeFieldPassword === 'text';

    const { control, handleSubmit, reset, watch } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validateAuthorize),
    });

    useEffect(() => reset(initialValues), [initialValues]);

    const handleClickShowPassoword = () => {
        if (showPassword) setTypeFieldPassword('password');
        else setTypeFieldPassword('text');
    };

    const handleOnSubmit = (values) => handleAuth(values, token);
    const handleOnClickCancel = () => prepareReject(token);

    return (
        <OpacityAnimation>
            <Wrapper px={3} py={2}>
                <Box display="flex" alignItems="center">
                    <Typography variant="h4">
                        {t('Authorization to Sunwise')}
                    </Typography>
                    <Box sx={{ ml: 'auto' }}>
                        <Typography variant="h3">2/2</Typography>
                    </Box>
                </Box>

                <Divider sx={{ my: 2 }} />

                <Typography variant="body1">
                    Autorizo expresamente a Sunwise S.A.P.I de C.V., para lleve
                    a cabo Investigaciones, sobre mi comportamiento Crediticio
                    en SIC que estime conveniente. Conozco la naturaleza y
                    alcance de la información que se solicitará, del uso que se
                    le dará y que se podrá realizar consultas periódicas de mi
                    historial crediticio, consiento que esta autorización tenga
                    vigencia de 3 años contados a partir de hoy, y en su caso
                    mientras mantengamos relación jurídica.
                </Typography>
                <Box my={2}>
                    <form onSubmit={handleSubmit(handleOnSubmit)}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <ReactHookFormCheck
                                control={control}
                                label={t('I authorize')}
                                name="authorize"
                            />
                        </Box>

                        <PasswordInput
                            control={control}
                            handleClickShowPassoword={handleClickShowPassoword}
                            label="NIP"
                            name="nip"
                            showPassword={showPassword}
                            type={typeFieldPassword}
                            value={watch('nip')}
                        />

                        <ShowErrors
                            errors={authErrors.map((error) =>
                                getTranslatedErrorMessage(error)
                            )}
                        />

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: { xs: 'column', md: 'row' },
                                justifyContent: { xs: 'center', md: 'end' },
                                alignItems: 'center',
                            }}
                        >
                            <Button
                                disabled={!watch('authorize')}
                                sx={{
                                    mb: 2,
                                    width: { xs: '100%', md: 'auto' },
                                    mr: { xs: 0, md: 2 },
                                }}
                                type="submit"
                            >
                                {t('Confirm')}
                            </Button>

                            <Button
                                onClick={handleOnClickCancel}
                                sx={{ width: { xs: '100%', md: 'auto' } }}
                                type="button"
                                variant="dafault"
                            >
                                {t("I don't authorize")}
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Wrapper>
        </OpacityAnimation>
    );
};

AuthorizeForm.propTypes = {
    authErrors: PropTypes.array,
    handleAuth: PropTypes.func,
    initialValues: PropTypes.object,
    prepareReject: PropTypes.func,
    token: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
    authErrors: selectors.getAuthErrors,
    initialValues: selectors.getAuthorizeFormInitialValues,
});

const mapDispatchToProps = (dispatch) => ({
    handleAuth: (values, token) => dispatch(actions.handleAuth(values, token)),
    prepareReject: (token) => dispatch(actions.prepareReject(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthorizeForm);
