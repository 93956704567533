import React from 'react';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import PropTypes from 'prop-types';
import { Box, IconButton, Typography } from 'sunwise-ui';

const ModalHeader = ({ handleClickBack, title }) => {
    return (
        <Box alignItems="center" display="flex" gap={2}>
            {handleClickBack && (
                <IconButton onClick={handleClickBack}>
                    <ChevronLeftIcon />
                </IconButton>
            )}
            <Typography variant="h5">{title}</Typography>
        </Box>
    );
};

ModalHeader.propTypes = {
    handleClickBack: PropTypes.func,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default ModalHeader;
