import { createSlice } from '@reduxjs/toolkit';

import {
    APPROVE_INTERACTIVE_PROPOSAL,
    APPROVE_INTERACTIVE_PROPOSAL_FAILURE,
    APPROVE_INTERACTIVE_PROPOSAL_SUCCESS,
    DELETE_INTERACTIVE_PROPOSAL_SIGNATURE,
    DELETE_INTERACTIVE_PROPOSAL_SIGNATURE_FAILURE,
    DELETE_INTERACTIVE_PROPOSAL_SIGNATURE_SUCCESS,
    DOWNLOAD_DATASHEET,
    DOWNLOAD_DATASHEET_FAILURE,
    DOWNLOAD_DATASHEET_SUCCESS,
    FETCH_BRANDING,
    FETCH_BRANDING_FAILURE,
    FETCH_BRANDING_SUCCESS,
    FETCH_COMMERCIAL_OFFER,
    FETCH_COMMERCIAL_OFFER_FAILURE,
    FETCH_COMMERCIAL_OFFER_SUCCESS,
    FETCH_ELECTRIC_BILLS,
    FETCH_ELECTRIC_BILLS_FAILURE,
    FETCH_ELECTRIC_BILLS_SUCCESS,
    FETCH_FINANCING_TEMPLATE,
    FETCH_FINANCING_TEMPLATE_SUCCESS,
    FETCH_FINANCING_TEMPLATE_FAILURE,
    FETCH_INTERACTIVE_PROPOSAL,
    FETCH_INTERACTIVE_PROPOSAL_FAILURE,
    FETCH_INTERACTIVE_PROPOSAL_SUCCESS,
    FETCH_INTERACTIVE_PROPOSAL_CONTENT,
    FETCH_INTERACTIVE_PROPOSAL_CONTENT_FAILURE,
    FETCH_INTERACTIVE_PROPOSAL_CONTENT_SUCCESS,
    FETCH_INTERACTIVE_PROPOSAL_SIGNATURE,
    FETCH_INTERACTIVE_PROPOSAL_SIGNATURE_FAILURE,
    FETCH_INTERACTIVE_PROPOSAL_SIGNATURE_SUCCESS,
    FETCH_SMART_DOCUMENT_CONTENT,
    FETCH_SMART_DOCUMENT_CONTENT_FAILURE,
    FETCH_SMART_DOCUMENT_CONTENT_SUCCESS,
    INITIAL_VALUES,
    RESET,
    SAVE_INTERACTIVE_PROPOSAL_SIGNATURE,
    SAVE_INTERACTIVE_PROPOSAL_SIGNATURE_FAILURE,
    SAVE_INTERACTIVE_PROPOSAL_SIGNATURE_SUCCESS,
    SAVE_VISITS,
    SAVE_VISITS_FAILURE,
    SAVE_VISITS_SUCCESS,
    SET_BRANDING,
    SET_IS_FINANCING_MODE,
    SET_IS_OPEN_APPROVAL_MODAL,
    SET_THEME,
    TOGGLE_TOOLBAR_MESSAGE,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    approvalInteractiveProposal: {
        errors: [],
        isApproving: false,
    },
    branding: {
        data: null,
        isFetching: true,
    },
    commercialOffer: {
        isFetching: false,
        data: {},
        errors: [],
    },
    deleteInteractiveProposalSignature: {
        errors: [],
        isDeleting: false,
    },
    downloadDatasheet: {
        errors: [],
        isDownloading: false,
    },
    fetchElectricBills: {
        data: [],
        errors: [],
        isFetching: false,
    },
    financingTemplate: {
        data: null,
        errors: [],
        isFetching: false,
    },
    isFinancingMode: false,
    initialValues: {
        email: '',
        is_approved: false,
    },
    interactiveProposal: {
        data: {},
        errors: [],
        hasError: false,
        isFetching: false,
    },
    interactiveProposalContent: {
        data: {},
        errors: [],
        isFetching: false,
    },
    interactiveProposalSignature: {
        data: {},
        errors: {},
        isFetching: false,
    },
    isOpenApprovalModal: false,
    saveInteractiveProposalSignature: {
        errors: [],
        isSaving: false,
    },
    saveCounterVisits: {
        errors: [],
        isSaving: false,
    },
    smartDocumentContent: {
        data: {},
        errors: [],
        isFetching: false,
    },
    showToolbarMessage: false,
    theme: 'light',
};

const interactiveProposalPreviewSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [APPROVE_INTERACTIVE_PROPOSAL]: (state) => {
            state.approvalInteractiveProposal.isApproving = true;
        },
        [APPROVE_INTERACTIVE_PROPOSAL_FAILURE]: (state, action) => {
            state.approvalInteractiveProposal = {
                ...state.approvalInteractiveProposal,
                errors: action.payload,
                isApproving: false,
            };
        },
        [APPROVE_INTERACTIVE_PROPOSAL_SUCCESS]: (state) => {
            state.approvalInteractiveProposal.isApproving = false;
        },
        [DELETE_INTERACTIVE_PROPOSAL_SIGNATURE]: (state) => {
            state.deleteInteractiveProposalSignature.isDeleting = true;
        },
        [DELETE_INTERACTIVE_PROPOSAL_SIGNATURE_FAILURE]: (state, action) => {
            state.deleteInteractiveProposalSignature = {
                ...state.deleteInteractiveProposalSignature,
                errors: action.payload,
                isDeleting: false,
            };
        },
        [DELETE_INTERACTIVE_PROPOSAL_SIGNATURE_SUCCESS]: (state) => {
            state.deleteInteractiveProposalSignature.isDeleting = false;
        },
        [DOWNLOAD_DATASHEET]: (state) => {
            state.downloadDatasheet.isDownloading = false;
        },
        [DOWNLOAD_DATASHEET_FAILURE]: (state, action) => {
            state.downloadDatasheet = {
                ...state.downloadDatasheet,
                errors: action.payload,
                isApproving: false,
            };
        },
        [DOWNLOAD_DATASHEET_SUCCESS]: (state) => {
            state.downloadDatasheet.isDownloading = false;
        },
        [FETCH_BRANDING]: (state) => {
            state.branding.isFetching = true;
        },
        [FETCH_BRANDING_FAILURE]: (state, action) => {
            state.branding = {
                ...state.branding,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_BRANDING_SUCCESS]: (state, action) => {
            state.branding = {
                ...state.branding,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_COMMERCIAL_OFFER]: (state) => {
            state.commercialOffer.isFetching = true;
        },
        [FETCH_COMMERCIAL_OFFER_FAILURE]: (state, action) => {
            state.commercialOffer = {
                ...state.commercialOffer,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_COMMERCIAL_OFFER_SUCCESS]: (state, action) => {
            state.commercialOffer = {
                ...state.commercialOffer,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_ELECTRIC_BILLS]: (state) => {
            state.fetchElectricBills.isFetching = true;
        },
        [FETCH_ELECTRIC_BILLS_FAILURE]: (state, action) => {
            state.fetchElectricBills = {
                ...state.fetchElectricBills,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_ELECTRIC_BILLS_SUCCESS]: (state, action) => {
            state.fetchElectricBills = {
                ...state.fetchElectricBills,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_FINANCING_TEMPLATE]: (state) => {
            state.financingTemplate.isFetching = true;
        },
        [FETCH_FINANCING_TEMPLATE_FAILURE]: (state, action) => {
            state.financingTemplate = {
                ...state.financingTemplate,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_FINANCING_TEMPLATE_SUCCESS]: (state, action) => {
            state.financingTemplate = {
                ...state.financingTemplate,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_INTERACTIVE_PROPOSAL]: (state) => {
            state.interactiveProposal.isFetching = true;
        },
        [FETCH_INTERACTIVE_PROPOSAL_FAILURE]: (state, action) => {
            state.interactiveProposal = {
                ...state.interactiveProposal,
                errors: action.payload,
                hasError: true,
                isFetching: false,
            };
        },
        [FETCH_INTERACTIVE_PROPOSAL_SUCCESS]: (state, action) => {
            state.interactiveProposal = {
                ...state.interactiveProposal,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_INTERACTIVE_PROPOSAL_CONTENT]: (state) => {
            state.interactiveProposalContent.isFetching = true;
        },
        [FETCH_INTERACTIVE_PROPOSAL_CONTENT_FAILURE]: (state, action) => {
            state.interactiveProposalContent = {
                ...state.interactiveProposalContent,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_INTERACTIVE_PROPOSAL_CONTENT_SUCCESS]: (state, action) => {
            state.interactiveProposalContent = {
                ...state.interactiveProposalContent,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_INTERACTIVE_PROPOSAL_SIGNATURE]: (state) => {
            state.interactiveProposalSignature.isFetching = true;
        },
        [FETCH_INTERACTIVE_PROPOSAL_SIGNATURE_FAILURE]: (state, action) => {
            state.interactiveProposalSignature = {
                ...state.interactiveProposalSignature,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_INTERACTIVE_PROPOSAL_SIGNATURE_SUCCESS]: (state, action) => {
            state.interactiveProposalSignature = {
                ...state.interactiveProposalSignature,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_SMART_DOCUMENT_CONTENT]: (state) => {
            state.smartDocumentContent.isFetching = true;
        },
        [FETCH_SMART_DOCUMENT_CONTENT_FAILURE]: (state, action) => {
            state.smartDocumentContent = {
                ...state.smartDocumentContent,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_SMART_DOCUMENT_CONTENT_SUCCESS]: (state, action) => {
            state.smartDocumentContent = {
                ...state.smartDocumentContent,
                data: action.payload,
                isFetching: false,
            };
        },
        [INITIAL_VALUES]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [RESET]: () => INITIAL_STATE,
        [SAVE_INTERACTIVE_PROPOSAL_SIGNATURE]: (state) => {
            state.saveInteractiveProposalSignature.isSaving = true;
        },
        [SAVE_INTERACTIVE_PROPOSAL_SIGNATURE_FAILURE]: (state, action) => {
            state.saveInteractiveProposalSignature = {
                ...state.saveInteractiveProposalSignature,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_INTERACTIVE_PROPOSAL_SIGNATURE_SUCCESS]: (state) => {
            state.saveInteractiveProposalSignature.isSaving = false;
        },
        [SAVE_VISITS]: (state) => {
            state.saveCounterVisits.isSaving = true;
        },
        [SAVE_VISITS_FAILURE]: (state, action) => {
            state.saveCounterVisits = {
                ...state.saveCounterVisits,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_VISITS_SUCCESS]: (state) => {
            state.saveCounterVisits.isSaving = false;
        },
        [SET_BRANDING]: (state, action) => {
            state.branding = action.payload;
        },
        [SET_IS_FINANCING_MODE]: (state, action) => {
            state.isFinancingMode = action.payload;
        },
        [SET_IS_OPEN_APPROVAL_MODAL]: (state, action) => {
            state.isOpenApprovalModal = action.payload;
        },
        [SET_THEME]: (state, action) => {
            state.theme = action.payload;
        },
        [TOGGLE_TOOLBAR_MESSAGE]: (state, action) => {
            state.showToolbarMessage = action.payload;
        },
    },
});

export const interactiveProposalPreviewActions =
    interactiveProposalPreviewSlice.actions;

export default interactiveProposalPreviewSlice.reducer;
