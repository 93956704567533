import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { Box, Button, Divider, Typography } from 'sunwise-ui';

import OpacityAnimation from 'common/components/animations/OpacityAnimation';
import PasswordInput from 'common/components/form/PasswordInput';
import ReactHookFormCheck from 'common/components/form/rhf/ReactHookFormCheck';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import * as selectors from '../selectors';
import validateTerms from '../validateTerms';

import Terms from './Terms';

const Wrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        background: transparent;
        height: 7px;
        width: 7px;
    }
    &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.15);
        border-radius: 2px;
    }
`;

const TermsAndConditions = ({ handleAccepTerms, initialValues }) => {
    const { t } = useTranslation();
    const [typeFieldPassword, setTypeFieldPassword] = useState('password');
    const showPassword = typeFieldPassword === 'text';

    const { control, handleSubmit, reset, watch } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validateTerms),
    });

    useEffect(() => reset(initialValues), [initialValues]);

    const handleClickShowPassoword = () => {
        if (showPassword) setTypeFieldPassword('password');
        else setTypeFieldPassword('text');
    };

    return (
        <OpacityAnimation>
            <Wrapper px={3} py={2}>
                <Box display="flex" alignItems="center">
                    <Typography variant="h4">
                        {t('Terms and conditions')}
                    </Typography>
                    <Box sx={{ ml: 'auto' }}>
                        <Typography variant="h3">1/2</Typography>
                    </Box>
                </Box>

                <Divider sx={{ my: 2 }} />

                <Terms />

                {watch('currentNip')}

                <Box my={2}>
                    <form onSubmit={handleSubmit(handleAccepTerms)}>
                        <ReactHookFormCheck
                            control={control}
                            label={t('I accept the terms and conditions')}
                            name="accept_terms"
                        />

                        <PasswordInput
                            control={control}
                            handleClickShowPassoword={handleClickShowPassoword}
                            label="NIP"
                            name="nip"
                            showPassword={showPassword}
                            type={typeFieldPassword}
                            value={watch('nip')}
                        />

                        <Box textAlign="right">
                            <Button
                                disabled={!watch('accept_terms')}
                                sx={{
                                    mb: 2,
                                    width: { xs: '100%', md: 'auto' },
                                }}
                                type="submit"
                            >
                                {t('Continue')}
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Wrapper>
        </OpacityAnimation>
    );
};

TermsAndConditions.propTypes = {
    handleAccepTerms: PropTypes.func,
    initialValues: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getTermsInitialValues,
});

const mapDispatchToProps = (dispatch) => ({
    handleAccepTerms: (values) => dispatch(actions.handleAccepTerms(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions);
