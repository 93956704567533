import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

/** FETCH BRANDING */
export const getBranding = createSelector(getModel, (model) => model.branding);

export const getIsFetchingBranding = createSelector(
    getBranding,
    (value) => value.isFetching
);

export const getBrandingConfig = createSelector(
    getBranding,
    (value) => value.data || {}
);

/** FETCH COMMERCIAL OFFER */

export const getCommercialOffer = createSelector(
    getModel,
    (model) => model.commercialOffer
);

export const getIsFetchingCommercialOffer = createSelector(
    getCommercialOffer,
    (value) => value.isFetching
);

export const getCommercialOfferData = createSelector(
    getCommercialOffer,
    (value) => value.data
);

export const getCommercialOfferErrors = createSelector(
    getCommercialOffer,
    (value) => value.errors
);

/** FETCH ELECTRIC BILLS */
export const getElectricBillsModel = createSelector(
    getModel,
    (model) => model.fetchElectricBills
);

export const getIsFetchingElectricBills = createSelector(
    getElectricBillsModel,
    (value) => value.isFetching
);

export const getElectricBillsData = createSelector(
    getElectricBillsModel,
    (value) => value.data
);

export const getElectricBillsErrors = createSelector(
    getElectricBillsModel,
    (value) => value.errors
);

// FETCH FINANCING TEMPLATE
export const getFinancingTemplate = createSelector(
    getModel,
    (model) => model.financingTemplate
);

export const getIsFetchingFinancingTemplate = createSelector(
    getFinancingTemplate,
    (value) => value.isFetching
);

/** FETCH INTERACTIVE PROPOSAL */
export const getInteractiveProposal = createSelector(
    getModel,
    (model) => model.interactiveProposal
);

export const getIsFetchingInteractiveProposal = createSelector(
    getInteractiveProposal,
    (value) => value.isFetching
);

export const getInteractiveProposalData = createSelector(
    getInteractiveProposal,
    (value) => value.data
);

export const getInteractiveProposalError = createSelector(
    getInteractiveProposal,
    (value) => value.error
);

export const getHasInteractiveProposalError = createSelector(
    getInteractiveProposal,
    (value) => value.hasError
);

/** FETCH INTERACTIVE PROPOSAL CONTENT */
export const getInteractiveProposalContent = createSelector(
    getModel,
    (model) => model.interactiveProposalContent
);

export const getIsFetchingInteractiveProposalContent = createSelector(
    getInteractiveProposalContent,
    (value) => value.isFetching
);

export const getInteractiveProposalContentData = createSelector(
    getInteractiveProposalContent,
    (value) => value.data
);

export const getInteractiveProposalContentErrors = createSelector(
    getInteractiveProposalContent,
    (value) => value.errors
);

/** FETCH INTERACTIVE PROPOSAL SIGNATURE */
export const getInteractiveProposalSignature = createSelector(
    getModel,
    (model) => model.interactiveProposalSignature
);

export const getIsFetchingInteractiveProposalSignature = createSelector(
    getInteractiveProposalSignature,
    (value) => value.isFetching
);

export const getInteractiveProposalSignatureData = createSelector(
    getInteractiveProposalSignature,
    (value) => value.data
);

export const getInteractiveProposalSignatureErrors = createSelector(
    getInteractiveProposalSignature,
    (value) => value.errors
);

/** FETCH SMART DOCUMENT CONTENT */
export const getSmartDocumentContent = createSelector(
    getModel,
    (model) => model.smartDocumentContent
);

export const getIsFetchingSmartDocumentContent = createSelector(
    getSmartDocumentContent,
    (value) => value.isFetching
);

export const getSmartDocumentContentData = createSelector(
    getSmartDocumentContent,
    (value) => value.data
);

export const getSmartDocumentContentErrors = createSelector(
    getSmartDocumentContent,
    (value) => value.errors
);

/** APPROVAL INTERACTIVE PROPOSAL */
export const getApprovalInteractiveProposal = createSelector(
    getModel,
    (model) => model.approvalInteractiveProposal
);

export const getIsApprovingInteractiveProposal = createSelector(
    getApprovalInteractiveProposal,
    (model) => model.isApproving
);

export const getApprovalInteractiveProposalErrors = createSelector(
    getApprovalInteractiveProposal,
    (value) => value.errors
);

/** DELETE INTERACTIVE PROPOSAL SIGNATURE */
export const getDeleteInteractiveProposalSignature = createSelector(
    getModel,
    (model) => model.deleteInteractiveProposalSignature
);

export const getIsDeletingInteractiveProposalSignature = createSelector(
    getDeleteInteractiveProposalSignature,
    (model) => model.isDeleting
);

export const getDeleteInteractiveProposalSignatureErrors = createSelector(
    getDeleteInteractiveProposalSignature,
    (value) => value.errors
);

/** SAVE INTERACTIVE PROPOSAL SIGNATURE */
export const getSaveInteractiveProposalSignature = createSelector(
    getModel,
    (model) => model.saveInteractiveProposalSignature
);

export const getIsSavingInteractiveProposalSignature = createSelector(
    getSaveInteractiveProposalSignature,
    (model) => model.isSaving
);

export const getSaveInteractiveProposalSignatureErrors = createSelector(
    getSaveInteractiveProposalSignature,
    (value) => value.errors
);

export const getIsFinancingMode = createSelector(
    getModel,
    (model) => model.isFinancingMode
);

export const getIsOpenApprovalModal = createSelector(
    getModel,
    (model) => model.isOpenApprovalModal
);

export const getShowToolbarMessage = createSelector(
    getModel,
    (model) => model.showToolbarMessage
);

export const getTheme = createSelector(getModel, (model) => model.theme);
