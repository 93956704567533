import get from 'lodash/get';

export const getCountryCurrency = () => ({
    locale: 'es-MX',
    name: 'Peso Mexicano',
    abbreviation: 'MXN',
    symbol: '$',
});

export const getCountryCurrencyIso = () => {
    const currency = getCountryCurrency();
    return get(currency, 'abbreviation', 'USD');
};

export const getCountryCurrencyLocale = () => {
    const currency = getCountryCurrency();
    return get(currency, 'locale', 'en-US').replace(/_/g, '-');
};

export const getNumberFormatPartsByCountryCurrency = () =>
    new Intl.NumberFormat(getCountryCurrencyLocale(), {
        style: 'currency',
        currency: getCountryCurrencyIso(),
    }).formatToParts(Math.PI * 10000);

export const getCountryCurrencyDecimalSeparator = () => {
    const parts = getNumberFormatPartsByCountryCurrency();
    const decimalSeparator = parts.find((p) => p.type === 'decimal');
    if (decimalSeparator) return decimalSeparator.value;
    return '.';
};

export const getCountryCurrencyThousandSeparator = () => {
    const parts = getNumberFormatPartsByCountryCurrency();
    const thousandSeparator = parts.find((p) => p.type === 'group');
    if (thousandSeparator) return thousandSeparator.value;
    return ',';
};
