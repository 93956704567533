import { NAME } from './constants';

export const FETCH_CREDIT_DOCUMENTS = `${NAME}/FETCH_CREDIT_DOCUMENTS`;
export const FETCH_CREDIT_DOCUMENTS_FAILURE = `${NAME}/FETCH_CREDIT_DOCUMENTS_FAILURE`;
export const FETCH_CREDIT_DOCUMENTS_SUCCESS = `${NAME}/FETCH_CREDIT_DOCUMENTS_SUCCESS`;

export const REQUEST_FINANCING = `${NAME}/REQUEST_FINANCING`;
export const REQUEST_FINANCING_FAILURE = `${NAME}/REQUEST_FINANCING_FAILURE`;
export const REQUEST_FINANCING_SUCCESS = `${NAME}/REQUEST_FINANCING_SUCCESS`;

export const RESET = `${NAME}/RESET`;

export const SET_IS_OPEN_MODAL = `${NAME}/SET_IS_OPEN_MODAL`;

export const SET_VIEW = `${NAME}/SET_VIEW`;
