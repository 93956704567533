import { object, ref, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default () => {
    const { INVALID_NIP_TEXT, REQUIRED_TEXT, minimumNumberCharacters } =
        getValidationTexts();

    return object().shape({
        accept_terms: string().required(REQUIRED_TEXT),
        currentNip: string().required(REQUIRED_TEXT),
        nip: string()
            .required(REQUIRED_TEXT)
            .oneOf([ref('currentNip'), null], INVALID_NIP_TEXT)
            .min(7, minimumNumberCharacters(7))
            .nullable(),
    });
};
