import React, { useEffect } from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Alert, Box, Button, Grid, Typography } from 'sunwise-ui';

import ReactHookFormInput from 'common/components/form/rhf/ReactHookFormInput';
import ShowErrors from 'common/components/ShowErrors';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import {
    getIsDisabled,
    getIsPassed,
    getNoAttempts,
    upperRFCFieldValue,
} from '../helpers';
import * as selectors from '../selectors';
import validate from '../validate';

const CreditBureauForm = ({
    bureauData,
    creditStatus,
    errors,
    initialValues,
    isFetching,
    isSaving,
    offerId,
    onSuccess,
    prepareSave,
    setIsEnabledEdition,
    status,
}) => {
    const { t } = useTranslation();

    const { control, handleSubmit, reset, setValue } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    useEffect(() => reset(initialValues), [initialValues]);

    const callback = () => {
        onSuccess();
        setIsEnabledEdition(false);
    };

    const handleOnSave = (values) => prepareSave(callback, offerId, values);

    const records = get(bureauData, 'records', { current: 0, limit: 1 });
    const isPassed = getIsPassed(status);
    const isDisabled = getIsDisabled(
        creditStatus,
        isFetching,
        isPassed,
        isSaving,
        records
    );

    return (
        <form onSubmit={handleSubmit(handleOnSave)}>
            <Grid container>
                <Grid item xs>
                    <ReactHookFormInput
                        control={control}
                        disabled={isDisabled}
                        label={t('Name')}
                        name="first_name"
                        variant="standard"
                    />
                    <ReactHookFormInput
                        control={control}
                        disabled={isDisabled}
                        label={t('Middle name')}
                        name="second_name"
                        variant="standard"
                    />
                    <ReactHookFormInput
                        control={control}
                        disabled={isDisabled}
                        label={t('Last name')}
                        name="first_last_name"
                        variant="standard"
                    />
                    <ReactHookFormInput
                        control={control}
                        disabled={isDisabled}
                        label={t('Second last name')}
                        name="second_last_name"
                        variant="standard"
                    />
                    <ReactHookFormInput
                        control={control}
                        disabled={isDisabled}
                        label="RFC"
                        name="rfc"
                        onChange={({ target }) =>
                            upperRFCFieldValue(setValue, target.value)
                        }
                        variant="standard"
                    />
                    <ReactHookFormInput
                        control={control}
                        disabled={isDisabled}
                        label={t('Email')}
                        name="email"
                        variant="standard"
                    />
                    <ReactHookFormInput
                        control={control}
                        disabled={isDisabled}
                        label={t('Street')}
                        name="street"
                        variant="standard"
                    />
                    <ReactHookFormInput
                        control={control}
                        disabled={isDisabled}
                        label={t('Suburb')}
                        name="suburb"
                        variant="standard"
                    />
                    <ReactHookFormInput
                        control={control}
                        disabled={isDisabled}
                        label={t('City')}
                        name="city"
                        variant="standard"
                    />

                    <ReactHookFormInput
                        control={control}
                        disabled={isDisabled}
                        label={t('State')}
                        name="state"
                        variant="standard"
                    />
                    <ReactHookFormInput
                        control={control}
                        disabled={isDisabled}
                        label={t('Zip code')}
                        name="zipcode"
                        variant="standard"
                    />
                    {errors && <ShowErrors errors={errors} />}
                    {getNoAttempts(records) && (
                        <Box my={2}>
                            <Alert severity="warning">
                                <Typography variant="caption" fontWeight="bold">
                                    {t(
                                        'You have exceeded the number of queries allowed per proposal'
                                    )}
                                </Typography>
                            </Alert>
                        </Box>
                    )}
                    <Box textAlign="right">
                        <Button
                            disabled={isDisabled}
                            sx={{ width: { xs: '100%', lg: 'auto' } }}
                            type="submit"
                        >
                            {t('Send')}
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    bureauData: selectors.getFetchData,
    errors: selectors.getSaveErrors,
    initialValues: selectors.getInitialValues,
    isFetching: selectors.getIsFetching,
    isSaving: selectors.getIsSaving,
});

const mapDispatchToProps = (dispatch) => ({
    prepareSave: (callback, offerId, values) =>
        dispatch(actions.prepareSave(callback, offerId, values)),
});

CreditBureauForm.propTypes = {
    bureauData: PropTypes.object,
    creditStatus: PropTypes.number,
    errors: PropTypes.array,
    initialValues: PropTypes.object,
    isFetching: PropTypes.bool,
    isSaving: PropTypes.bool,
    offerId: PropTypes.string,
    onSuccess: PropTypes.func,
    prepareSave: PropTypes.func,
    setIsEnabledEdition: PropTypes.func,
    status: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditBureauForm);
