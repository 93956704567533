import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import save from './save';

export default (callback, offerId, values) => (dispatch) => {
    if (!values.rfc) return false;
    const dataAlert = {
        confirmText: i18next.t('Send'),
        messages: [
            i18next.t(
                "The email address entered must be the customer's, otherwise the request may be rejected and the account may be suspended"
            ),
        ],
        onSuccess: () => dispatch(save(callback, offerId, values)),
        title: i18next.t('Confirmation'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };

    dispatch(alerts.actions.show(dataAlert));
};
