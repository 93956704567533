import { getTemplatecontent } from 'common/api/renderContent';

import {
    FETCH_FINANCING_TEMPLATE,
    FETCH_FINANCING_TEMPLATE_SUCCESS,
    FETCH_FINANCING_TEMPLATE_FAILURE,
} from '../actionTypes';
import { interactiveProposalPreviewActions } from '../reducer';

export default (id, initTemplate) => (dispatch) =>
    new Promise((resolve) => {
        dispatch(interactiveProposalPreviewActions[FETCH_FINANCING_TEMPLATE]());

        return getTemplatecontent(id)
            .then((response) => {
                dispatch(
                    interactiveProposalPreviewActions[
                        FETCH_FINANCING_TEMPLATE_SUCCESS
                    ](response.data)
                );
                initTemplate(response.data, id);
            })
            .catch((error) =>
                dispatch(
                    interactiveProposalPreviewActions[
                        FETCH_FINANCING_TEMPLATE_FAILURE
                    ](error?.response?.data?.errors)
                )
            )
            .finally(resolve);
    });
