import { styled } from '@mui/material/styles';
import { Box } from 'sunwise-ui';

import { scroll } from 'common/utils/mixins';

export default styled(Box)(
    ({ theme }) => `
    background:  ${
        theme.palette.mode === 'dark' ? '#000' : 'rgb(248, 252, 255)'
    };
    overflow-y: scroll;
    position: relative;
    width: 100%;
    ${scroll.custom``}

    .apexcharts-theme-light {
        color: black;
    }
`
);
