import { getBranding } from 'common/api/interactiveProposal';

import {
    FETCH_BRANDING,
    FETCH_BRANDING_FAILURE,
    FETCH_BRANDING_SUCCESS,
} from '../actionTypes';
import { interactiveProposalPreviewActions } from '../reducer';

export default (id) => (dispatch) =>
    new Promise((resolve) => {
        dispatch(interactiveProposalPreviewActions[FETCH_BRANDING]());

        getBranding(id)
            .then((response) =>
                dispatch(
                    interactiveProposalPreviewActions[FETCH_BRANDING_SUCCESS](
                        response?.data?.branding
                    )
                )
            )
            .catch((error) =>
                dispatch(
                    interactiveProposalPreviewActions[FETCH_BRANDING_FAILURE](
                        error?.response?.data?.errors
                    )
                )
            )
            .finally(resolve);
    });
