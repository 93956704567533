import React from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ImageIcon from '@mui/icons-material/Image';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Avatar,
    Box,
    Card,
    IconButton,
    StackItem,
    Typography,
} from 'sunwise-ui';

import {
    CreditDocuments,
    StepperTimeline,
    TitleWithDetail,
} from 'common/components';
import { handleFileURL } from 'common/utils/helpers';

import { VIEWS } from '../constants';

const FinancingRequest = ({
    applicationDocuments,
    documents,
    creditStatus,
    financing,
    handleClickBack,
    hasRequestBureau,
    isFetchingCreditDocuments,
    setSelectedFile,
    setView,
    showInModal = false,
}) => {
    const { t } = useTranslation();

    const handleClickUploadDocument = (file) => {
        setSelectedFile(file);
        setView(VIEWS.UPLOAD_DOCUMENT);
    };

    return (
        <Card
            sx={{
                minHeight: '100%',
                mb: 0,
                ...(showInModal ? { boxShadow: 'none' } : {}),
            }}
        >
            {!showInModal && (
                <Card.Header>
                    <Box alignItems="center" display="flex" gap={2}>
                        <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
                            <IconButton onClick={handleClickBack}>
                                <ChevronLeftIcon />
                            </IconButton>
                        </Box>
                        <Typography variant="h5">{t('Financing')}</Typography>
                    </Box>
                </Card.Header>
            )}
            <Card.Body>
                <StackItem
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        gap: 2,
                        mb: 1,
                    }}
                >
                    <Avatar
                        src={handleFileURL(
                            get(financing, 'image', ''),
                            process.env.REACT_APP_S3_MEDIA_PATH
                        )}
                        sx={{
                            height: 40,
                            width: 40,
                        }}
                    >
                        <ImageIcon />
                    </Avatar>
                    <Typography variant="body2">{`${financing?.name} (${t(
                        'Proposal'
                    )})`}</Typography>
                    <IconButton
                        onClick={() => setView(VIEWS.FINANCING_DETAILS)}
                        sx={{ ml: 'auto' }}
                    >
                        <ChevronRightIcon />
                    </IconButton>
                </StackItem>
                <StepperTimeline status={creditStatus} />
                <StackItem mt={3}>
                    <Box display="flex" alignItems="center" gap={1}>
                        <CheckCircleIcon
                            sx={{
                                color: hasRequestBureau
                                    ? '#ff9a00'
                                    : 'rgba(0, 0, 0, 0.38)',
                            }}
                        />
                        <Typography variant="body2">
                            {t('Credit bureau request')}
                        </Typography>
                        <IconButton
                            onClick={() => setView(VIEWS.BUREAU_CREDIT)}
                            sx={{ ml: 'auto' }}
                        >
                            <ChevronRightIcon />
                        </IconButton>
                    </Box>
                </StackItem>
                {hasRequestBureau && (
                    <>
                        <TitleWithDetail variant="body2">
                            {t('Request form')}
                        </TitleWithDetail>
                        <CreditDocuments
                            documents={applicationDocuments}
                            handleClickUpload={handleClickUploadDocument}
                            isFetching={isFetchingCreditDocuments}
                        />
                        <TitleWithDetail variant="body2">
                            {t('Document', { count: 2 })}
                        </TitleWithDetail>
                        <CreditDocuments
                            documents={documents}
                            handleClickUpload={handleClickUploadDocument}
                            isFetching={isFetchingCreditDocuments}
                        />
                    </>
                )}
            </Card.Body>
        </Card>
    );
};

FinancingRequest.propTypes = {
    applicationDocuments: PropTypes.array,
    documents: PropTypes.array,
    creditStatus: PropTypes.number,
    financing: PropTypes.object,
    handleClickBack: PropTypes.func,
    hasRequestBureau: PropTypes.bool,
    isFetchingCreditDocuments: PropTypes.bool,
    setSelectedFile: PropTypes.func,
    setView: PropTypes.func,
    showInModal: PropTypes.bool,
};

export default FinancingRequest;
