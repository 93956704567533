import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Button } from 'sunwise-ui';

import ApprovalData from './ApprovalData';

const ApprovalCard = ({
    handleClickOpenApprovalModal,
    hasApproved,
    hasSignature,
    interactiveProposalSignatureData,
}) => {
    const { t } = useTranslation();
    if (!hasApproved && !hasSignature)
        return (
            <Box py={2} sx={{ display: { md: 'block', xs: 'none' } }}>
                <Button
                    fullWidth
                    onClick={() => handleClickOpenApprovalModal()}
                    size="small"
                    variant="outlined"
                >
                    {t('Approve')}
                </Button>
            </Box>
        );

    return (
        <ApprovalData
            approveEmail={get(interactiveProposalSignatureData, 'email', '')}
            hasApproved={hasApproved}
            onClick={() => handleClickOpenApprovalModal()}
            updatedAt={get(interactiveProposalSignatureData, 'updated', '')}
        />
    );
};

ApprovalCard.propTypes = {
    handleClickOpenApprovalModal: PropTypes.func,
    hasApproved: PropTypes.bool,
    hasSignature: PropTypes.bool,
    interactiveProposalSignatureData: PropTypes.object,
};

export default ApprovalCard;
