import i18next from 'i18next';

import { uploadCreditDocument } from 'common/api/v1/sunpay';
import showToast from 'common/utils/showToast';

import {
    UPLOAD_DOCUMENT,
    UPLOAD_DOCUMENT_FAILURE,
    UPLOAD_DOCUMENT_SUCCESS,
} from '../actionTypes';
import { uploadDocumentActions } from '../reducer';

export default (
        handleUpdateFile,
        onSuccess,
        onSuccessCallback,
        selectedFileId,
        tempFile
    ) =>
    (dispatch) => {
        const formData = new FormData();
        formData.append('file', tempFile);
        dispatch(uploadDocumentActions[UPLOAD_DOCUMENT]());
        uploadCreditDocument(selectedFileId, formData)
            .then((response) => {
                dispatch(uploadDocumentActions[UPLOAD_DOCUMENT_SUCCESS]());
                if (handleUpdateFile) handleUpdateFile(response?.data?.data);
                if (onSuccess) onSuccess();
                if (onSuccessCallback) onSuccessCallback();
                showToast({
                    body: i18next.t('The file was uploaded successfully'),
                });
            })
            .catch((error) =>
                dispatch(
                    uploadDocumentActions[UPLOAD_DOCUMENT_FAILURE](
                        error?.response?.data?.errors
                    )
                )
            );
    };
