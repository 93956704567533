import React from 'react';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Card, Grid, IconButton, Typography } from 'sunwise-ui';

import IntlNumberInputField from 'common/components/IntlNumberInputField';

const FinancingDetails = ({
    creditItem,
    financing,
    handleClickBack,
    showInModal = false,
}) => {
    const { t } = useTranslation();
    const parameters = get(creditItem, 'parameters', {});
    const { amount = 0, rate = 0, term = 0, hitch = 0 } = parameters;

    return (
        <Card
            sx={{
                minHeight: '100%',
                mb: 0,
                ...(showInModal ? { boxShadow: 'none' } : {}),
            }}
        >
            {!showInModal && (
                <Card.Header>
                    <Box alignItems="center" display="flex" gap={2}>
                        <IconButton onClick={handleClickBack}>
                            <ChevronLeftIcon />
                        </IconButton>
                        <Typography variant="h5">{`${financing?.name} (${t(
                            'Proposal'
                        )})`}</Typography>
                    </Box>
                </Card.Header>
            )}
            <Card.Body>
                <Grid container>
                    <Grid item xs>
                        <IntlNumberInputField
                            allowNegativeValue={false}
                            disabled
                            fullWidth
                            label={t('Total')}
                            max={99}
                            min={0}
                            prepend="$"
                            step="any"
                            sx={{ mb: { xs: 2, lg: 3 } }}
                            value={amount}
                            variant="standard"
                        />
                        <IntlNumberInputField
                            allowNegativeValue={false}
                            disabled
                            fullWidth
                            label={t('Total to be financed')}
                            max={99}
                            min={0}
                            prepend="$"
                            step="any"
                            sx={{ mb: { xs: 2, lg: 3 } }}
                            value={parseFloat(amount - hitch).toFixed(4)}
                            variant="standard"
                        />
                        <IntlNumberInputField
                            allowNegativeValue={false}
                            disabled
                            fullWidth
                            label={t('Down payment')}
                            max={99}
                            min={0}
                            prepend="$"
                            step="any"
                            sx={{ mb: { xs: 2, lg: 3 } }}
                            value={hitch}
                            variant="standard"
                        />
                        <Grid container>
                            <Grid item md={9} xs={18}>
                                <IntlNumberInputField
                                    allowNegativeValue={false}
                                    append="%"
                                    disabled
                                    fullWidth
                                    label={t('Interest rate')}
                                    max={99}
                                    min={0}
                                    step="any"
                                    sx={{ mb: { xs: 2, lg: 3 } }}
                                    value={rate}
                                    variant="standard"
                                />
                            </Grid>
                            <Grid item md={9} xs={18}>
                                <IntlNumberInputField
                                    allowNegativeValue={false}
                                    append={t('Month', { count: 2 })}
                                    disabled
                                    fullWidth
                                    label={t('Term')}
                                    max={99}
                                    min={0}
                                    step="any"
                                    sx={{ mb: { xs: 2, lg: 3 } }}
                                    value={term}
                                    variant="standard"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Card.Body>
        </Card>
    );
};

FinancingDetails.propTypes = {
    creditItem: PropTypes.object,
    financing: PropTypes.object,
    handleClickBack: PropTypes.func,
    showInModal: PropTypes.bool,
};

export default FinancingDetails;
