import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { reducer as templateReducer } from 'sunwise-template-core';

import alerts from 'common/modules/alerts';
import fileUploader from 'common/modules/fileUploader';
import lisaFiles from 'common/modules/lisaFiles';
import orm from 'common/orm';
import createReducer from 'common/orm/createReducer';

import creditBureau from './modules/creditBureau';
import creditBureauCard from './modules/creditBureauCard';
import financing from './modules/financing';
import interactiveProposalPreview from './modules/interactiveProposalPreview';
import uploadDocument from './modules/uploadDocument';

export default (history) =>
    combineReducers({
        [alerts.NAME]: alerts.reducer,
        [creditBureau.NAME]: creditBureau.reducer,
        [creditBureauCard.NAME]: creditBureauCard.reducer,
        [fileUploader.NAME]: fileUploader.reducer,
        [financing.NAME]: financing.reducer,
        [interactiveProposalPreview.NAME]: interactiveProposalPreview.reducer,
        [lisaFiles.NAME]: lisaFiles.reducer,
        [uploadDocument.NAME]: uploadDocument.reducer,
        entities: createReducer(orm),
        router: connectRouter(history),
        sunwiseTemplateCore: templateReducer,
    });
