import { authorization } from 'common/api/v1/burocredito';

import { AUTH, AUTH_FAILURE, AUTH_SUCCESS } from '../actionTypes';
import { BUREAU_STEPS } from '../constants';
import { creditBureauActions } from '../reducer';

import setCurrentStep from './setCurrentStep';

export default (data, token) => (dispatch) => {
    dispatch(creditBureauActions[AUTH]());

    const values = { nip: data.nip };

    authorization(values, token)
        .then((response) => {
            dispatch(creditBureauActions[AUTH_SUCCESS](response.data));
            dispatch(setCurrentStep(BUREAU_STEPS.COMPLETE));
        })
        .catch((error) =>
            dispatch(
                creditBureauActions[AUTH_FAILURE](error?.response?.data?.errors)
            )
        );
};
