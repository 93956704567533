import { enUS, es, ptBR } from 'date-fns/locale';
import get from 'lodash/get';

import {
    SUPPORTED_LANGUAGES,
    DEFAULT_LANGUAGE,
    DATE_FORMATS,
} from '../../constants/multiregion';

export const getDateFormatByLocale = () => {
    return DATE_FORMATS[getIsoCodeCurrentLocale()];
};

export const getUserSettings = () => ({
    language: {
        key: 'es-mx',
        name: 'Español (México)',
    },
    timezone: {
        key: 'America/Merida',
        name: 'hora central UTC-06 (America/Merida)',
    },
    auto_timezone: false,
    expanded_sidebar: false,
    autofill_panels: false,
    has_all_branchoffices: true,
    has_limited_contacts: false,
    mode: 'LIGHT',
});

export const getIanaTimezoneKey = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const getIsoCodeDetectedLanguage = () => {
    return (
        Intl.DateTimeFormat()
            .resolvedOptions()
            .locale.toLowerCase()
            .split('-')[0] || 'en'
    );
};

export const getRuntimeLanguage = () => {
    return (
        SUPPORTED_LANGUAGES[getIsoCodeDetectedLanguage()] || DEFAULT_LANGUAGE
    );
};

export const getCurrentLanguage = () => {
    const settings = getUserSettings();
    return get(settings, 'language.key', getRuntimeLanguage());
};

export const getIsoCodeCurrentLocale = () => {
    const language = getCurrentLanguage();
    return language.split('-')[0];
};

export const getLocaleEquivalenceForDateFns = () => {
    const locales = { en: enUS, es: es, pt: ptBR };
    return locales[getIsoCodeCurrentLocale()];
};

export const getTimezoneUserSettings = () => {
    const settings = getUserSettings();
    return get(settings, 'timezone.key', getIanaTimezoneKey());
};
