import React, { useEffect, useRef, useState } from 'react';

import { styled } from '@mui/material/styles';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Grid, Typography } from 'sunwise-ui';

import {
    ReactHookFormCheck,
    ReactHookFormInput,
} from 'common/components/form/rhf';
import SignatureView from 'common/components/SignatureView';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import * as selectors from '../selectors';
import validateApprovalForm from '../validateApprovalForm';

const SignatureContent = styled(Box)`
    display: block;
    margin: 0 auto;
    width: 100%;
    height: 400px;
`;

const ApprovalForm = ({
    deleteInteractiveProposalSignature,
    initialValues,
    interactiveProposalSignatureData,
    offerId,
    save,
    saveInteractiveProposalSignature,
}) => {
    const { t } = useTranslation();
    const inputRef = useRef(null);
    const signatureContetRef = useRef(null);
    const [hasStroke, setHasStroke] = useState(false);
    const [dataSignature, setDataSignature] = useState(null);
    const signatureId = get(interactiveProposalSignatureData, 'id', null);

    const { control, handleSubmit, reset, watch } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validateApprovalForm),
    });

    useEffect(() => reset(initialValues), [initialValues]);

    const saveStroke = () => {
        const data = inputRef.current.getData();
        const image = inputRef.current.getDataURL();
        setDataSignature(data);
        saveInteractiveProposalSignature(offerId, {
            signature: data,
            base64: image,
        });
    };

    const handleSave = (values) =>
        save({ ...values, offerId }, () => (hasStroke ? saveStroke() : {}));

    const signatureJson = get(
        interactiveProposalSignatureData,
        'signature',
        {}
    );

    const resetStroke = () => {
        inputRef.current.clearSignature();
        setHasStroke(false);
        setDataSignature(null);
        if (signatureId && !isEmpty(signatureJson)) {
            deleteInteractiveProposalSignature(offerId);
        }
    };

    const handleOnBeginStroke = () => setHasStroke(true);

    return (
        <form onSubmit={handleSubmit(handleSave)}>
            <Box>
                <Grid container spacing={0}>
                    <Grid item xs={18}>
                        <ReactHookFormInput
                            control={control}
                            label={t('Email')}
                            name="email"
                        />
                    </Grid>
                    <Grid item xs={18}>
                        <Typography variant="body2">
                            {t(
                                'Add my digital signature to all documents submitted'
                            )}
                        </Typography>
                        <Box sx={{ width: '100%' }} mt={1} mb={2}>
                            <SignatureContent ref={signatureContetRef}>
                                <SignatureView
                                    initialData={dataSignature}
                                    onBeginStroke={handleOnBeginStroke}
                                    ref={inputRef}
                                />
                            </SignatureContent>
                        </Box>
                    </Grid>
                    <Grid item xs={18}>
                        <Grid aligitems="center" container spacing={0}>
                            <Grid
                                item
                                md={15}
                                xs={18}
                                sx={{ order: { xs: 2, md: 1 } }}
                            >
                                <ReactHookFormCheck
                                    control={control}
                                    label={t(
                                        'I have read and agree with this Proposal as well as the attached Documents'
                                    )}
                                    name="is_approved"
                                />
                            </Grid>
                            <Grid
                                hidden={!hasStroke}
                                item
                                md={3}
                                xs={18}
                                textAlign="right"
                                sx={{ order: { xs: 1, md: 2 } }}
                            >
                                <Button
                                    color="error"
                                    onClick={resetStroke}
                                    sx={{
                                        width: { xs: '100%', md: 'auto' },
                                        '&.MuiButton-root': {
                                            mb: { md: 0, xs: 2 },
                                        },
                                    }}
                                    type="button"
                                    variant="text"
                                >
                                    {t('Delete')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container justifyContent="center" mt={2}>
                    <Grid
                        item
                        xs={18}
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'row' },
                            justifyContent: { xs: 'center', md: 'right' },
                        }}
                    >
                        <Button
                            color="secondary"
                            onClick={() => reset()}
                            sx={{
                                width: { xs: '100%', md: 'auto' },
                                order: { xs: 2, md: 1 },
                                mr: { xs: 0, md: 2 },
                            }}
                            type="button"
                            variant="text"
                        >
                            {t('Cancel')}
                        </Button>
                        <Button
                            disabled={!watch('is_approved') || !hasStroke}
                            sx={{
                                width: { xs: '100%', md: 'auto' },
                                order: { xs: 1, md: 2 },
                                mb: { xs: 2, md: 0 },
                            }}
                            type="submit"
                        >
                            {t('Approve')}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValues,
});

const mapDispatchToProps = (dispatch) => ({
    save: (values, onSuccesCallback) =>
        dispatch(actions.approveInteractiveProposal(values, onSuccesCallback)),
});

ApprovalForm.propTypes = {
    deleteInteractiveProposalSignature: PropTypes.func,
    initialValues: PropTypes.object,
    interactiveProposalSignatureData: PropTypes.object,
    offerId: PropTypes.string,
    save: PropTypes.func,
    saveInteractiveProposalSignature: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalForm);
