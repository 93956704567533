import React, { useEffect, useState } from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box } from 'sunwise-ui';

import CreditBureauCard from '../creditBureauCard';
import * as creditBureauCardActions from '../creditBureauCard/actions';
import UploadDocument from '../uploadDocument';

import * as actions from './actions';
import {
    FinancingDetails,
    FinancingRequest,
    InstallerCard,
} from './components';
import Wrapper from './components/Wrapper';
import { VIEWS } from './constants';
import * as selectors from './selectors';

const Container = ({
    applicationDocuments,
    creditDocuments,
    documents,
    fetchCreditBureauData,
    fetchCreditDocuments,
    financing,
    handleClickBack,
    isFetchingCreditDocuments,
    offerId,
    proposalData,
    setView,
    view,
}) => {
    const [expanded, setExpanded] = useState(false);
    const [selectedFile, setSelectedFile] = useState({});
    const creditItem = get(proposalData, 'credit_item', {});
    const creditApplicationId = get(
        proposalData,
        'credit_application_id',
        null
    );
    const creditStatus = get(proposalData, 'credit_application.status', null);
    const agent = get(creditItem, 'financier_installer.agent', {});
    const hasRequestBureau = get(proposalData, 'has_request_buro', false);

    useEffect(() => {
        fetchCreditDocuments(creditApplicationId);
    }, [creditApplicationId]);

    useEffect(() => {
        if (hasRequestBureau && offerId) {
            fetchCreditBureauData(offerId);
        }
    }, [hasRequestBureau]);

    const handleChange = (_, isExpanded) => {
        setExpanded(isExpanded);
    };

    const handleUpdateFile = (file) => setSelectedFile(file);

    const renderContent = () => {
        switch (view) {
            case VIEWS.BUREAU_CREDIT:
                return (
                    <CreditBureauCard.Container
                        creditStatus={creditStatus}
                        handleClickBack={() => setView(VIEWS.FINANCING_REQUEST)}
                        hasRequestBureau={hasRequestBureau}
                        offerId={offerId}
                        onSuccess={() => {
                            setView(VIEWS.FINANCING_REQUEST);
                        }}
                    />
                );
            case VIEWS.FINANCING_DETAILS:
                return (
                    <FinancingDetails
                        creditItem={creditItem}
                        financing={financing}
                        handleClickBack={() => setView(VIEWS.FINANCING_REQUEST)}
                    />
                );
            case VIEWS.UPLOAD_DOCUMENT:
                return (
                    <UploadDocument.Container
                        creditItem={creditItem}
                        handleClickBack={() => setView(VIEWS.FINANCING_REQUEST)}
                        handleUpdateFile={handleUpdateFile}
                        onSuccessCallback={() =>
                            fetchCreditDocuments(creditApplicationId)
                        }
                        selectedFile={selectedFile}
                    />
                );
            default:
                return (
                    <FinancingRequest
                        applicationDocuments={applicationDocuments}
                        creditDocuments={creditDocuments}
                        documents={documents}
                        creditStatus={creditStatus}
                        financing={financing}
                        handleClickBack={handleClickBack}
                        hasRequestBureau={get(
                            proposalData,
                            'has_request_buro',
                            false
                        )}
                        isFetchingCreditDocuments={isFetchingCreditDocuments}
                        setSelectedFile={setSelectedFile}
                        setView={setView}
                    />
                );
        }
    };

    return (
        <Box display="flex" flexDirection="column" sx={{ height: '100%' }}>
            <Wrapper
                mb={2}
                pb={2}
                px={2}
                sx={{
                    height: expanded
                        ? 'calc(100% - 80px)'
                        : 'calc(100% - 64px)',
                }}
            >
                {renderContent()}
            </Wrapper>
            <InstallerCard
                agent={agent}
                expanded={expanded}
                handleChange={handleChange}
            />
        </Box>
    );
};

Container.propTypes = {
    applicationDocuments: PropTypes.array,
    creditDocuments: PropTypes.array,
    documents: PropTypes.array,
    fetchCreditBureauData: PropTypes.func,
    fetchCreditDocuments: PropTypes.func,
    financing: PropTypes.object,
    handleClickBack: PropTypes.func,
    isFetchingCreditDocuments: PropTypes.bool,
    offerId: PropTypes.string,
    proposalData: PropTypes.object,
    setView: PropTypes.func,
    view: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
    applicationDocuments: selectors.getApplicationDocuments,
    documents: selectors.getDocuments,
    isFetchingCreditDocuments: selectors.getIsFetchingCreditDocuments,
    view: selectors.getSelectedView,
});

const mapDispatchToProps = (dispatch) => ({
    fetchCreditBureauData: (offerId) =>
        dispatch(creditBureauCardActions.fetchBureauData(offerId)),
    fetchCreditDocuments: (creditApplicationId) =>
        dispatch(actions.fetchCreditDocuments(creditApplicationId)),
    setView: (value) => dispatch(actions.setView(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);
