import { getProposalAnalytics } from 'common/api/interactiveProposal';

import {
    SAVE_VISITS,
    SAVE_VISITS_FAILURE,
    SAVE_VISITS_SUCCESS,
} from '../actionTypes';
import { interactiveProposalPreviewActions } from '../reducer';

export default (id) => (dispatch) => {
    dispatch(interactiveProposalPreviewActions[SAVE_VISITS]());

    getProposalAnalytics(id)
        .then(() =>
            dispatch(interactiveProposalPreviewActions[SAVE_VISITS_SUCCESS]())
        )
        .catch((error) =>
            dispatch(
                interactiveProposalPreviewActions[SAVE_VISITS_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
