import React from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import LinearProgress from '@mui/material/LinearProgress';
import { useTheme } from '@mui/material/styles';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Divider,
    IconButton,
    Stack,
    StackItem,
    Typography,
} from 'sunwise-ui';

import { DOCUMENT_STATUS } from 'common/constants';

const CreditDocuments = ({ documents, handleClickUpload, isFetching }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';
    const {
        APPROVED_STATUS,
        INPROCESS_STATUS,
        NO_DOCUMENT_STATUS,
        REJECTED_STATUS,
    } = DOCUMENT_STATUS;

    if (isFetching) {
        return (
            <Box p={2} sx={{ width: '100%' }}>
                <LinearProgress />
            </Box>
        );
    }

    if (isEmpty(documents)) {
        return (
            <Box display="flex" alignItems="center" p={2}>
                <Typography variant="caption">
                    {t('No documents assigned')}
                </Typography>
            </Box>
        );
    }

    const secondaryColor = isDarkMode ? '#eef4fa' : '#1b1c20';

    return (
        <Stack
            divider={<Divider />}
            sx={{ '.MuiDivider-fullWidth': { mt: 0 } }}
        >
            {documents.map((document) => {
                const hasBaseDocument =
                    document.status !== NO_DOCUMENT_STATUS && document.file;
                const hasDocumentSent = [
                    APPROVED_STATUS,
                    INPROCESS_STATUS,
                    REJECTED_STATUS,
                ].includes(document.status);
                return (
                    <StackItem key={document.id}>
                        <Box display="flex" alignItems="center" gap={1}>
                            <CheckCircleIcon
                                sx={{
                                    color: hasDocumentSent
                                        ? '#ff9a00'
                                        : secondaryColor,
                                }}
                            />
                            <Box flexGrow={1}>
                                <Typography variant="body2">
                                    {document.name}
                                </Typography>
                            </Box>
                            <Box
                                display="flex"
                                alignItems="center"
                                sx={{ ml: 'auto', width: '65px' }}
                            >
                                {hasBaseDocument && (
                                    <IconButton
                                        href={document.file}
                                        target="_blank"
                                    >
                                        <DownloadIcon />
                                    </IconButton>
                                )}
                                <IconButton
                                    onClick={() => handleClickUpload(document)}
                                >
                                    <UploadIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    </StackItem>
                );
            })}
        </Stack>
    );
};

CreditDocuments.propTypes = {
    documents: PropTypes.array,
    handleClickUpload: PropTypes.func,
    isFetching: PropTypes.bool,
};

export default CreditDocuments;
