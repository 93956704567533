import React from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { AppBar, Box, IconButton, Typography } from 'sunwise-ui';

const StyledAlert = styled(Box)(
    ({ theme }) => `
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 16px;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
        0px 5px 8px rgba(238, 244, 250, 0.14), 0px 1px 14px rgba(0, 0, 0, 0.12);
    background-color: ${
        theme.palette.mode === 'dark' ? 'rgba(0, 0, 0)' : 'rgb(248, 252, 255)'
    };
`
);

const AppBarMessage = ({ showToolbarMessage, toogleToolbarMessage }) => {
    const { t } = useTranslation();

    if (!showToolbarMessage) {
        return null;
    }

    return (
        <AppBar
            position="fixed"
            sx={{ background: 'transparent', boxShadow: 'none' }}
        >
            <StyledAlert>
                <CheckCircleIcon sx={{ color: '#ff9a00' }} />
                <Typography variant="body2" fontWeight="bold">
                    {t(
                        'You have attached a signature. Your installer will be notified shortly to follow up'
                    )}
                </Typography>
                <IconButton
                    onClick={() => toogleToolbarMessage(false)}
                    sx={{ ml: 'auto' }}
                >
                    <CloseIcon />
                </IconButton>
            </StyledAlert>
        </AppBar>
    );
};

AppBarMessage.propTypes = {
    showToolbarMessage: PropTypes.bool,
    toogleToolbarMessage: PropTypes.func,
};

export default AppBarMessage;
