import API from '../.';
import SUNWISE_API from '../..';
const ENTITY = 'burocredito';

export const authorization = (data, token) =>
    API.post('/api/v1/${ENTITY}/authorization/', data, {
        headers: { Authorization: `JWT ${token}` },
    });
export const getStatus = (proposalId) =>
    SUNWISE_API.get(`/api/v1/${ENTITY}/customer/${proposalId}/`);
export const reject = (token) =>
    API.post('/api/v1/${ENTITY}/rejecting/', null, {
        headers: { Authorization: `JWT ${token}` },
    });
export const save = (data) =>
    SUNWISE_API.post(`/api/v1/${ENTITY}/solicitude/`, { ...data });
