import React, { useContext, useEffect, useRef, useState } from 'react';

import { useTheme } from '@mui/material/styles';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { types } from 'sunwise-template-core';
import { editionLevels, withTemplateCore } from 'sunwise-template-core';
import { Grid } from 'sunwise-ui';

import SwipeableDrawer from 'common/components/SwipeableDrawer';
import * as lisaFileActions from 'common/modules/lisaFiles/actions';
import { LoadingContext } from 'common/utils/contexts';
import { getCurrentLanguage } from 'common/utils/helpers/multiregion';
import parseQueryString from 'common/utils/parseQueryString';

import * as financingActions from '../financing/actions';
import FinancingModal from '../financing/components/FinancingModal';
import FinancingContainer from '../financing/Container';
import * as financingSelectors from '../financing/selectors';

import * as actions from './actions';
import {
    AppBarMessage,
    ApprovalModal,
    ContactFooter,
    PreviewContent,
    NotFound,
    PageWrapper,
    ProposalResume,
    StyledSideBarColumn,
    SupportMenuContent,
} from './components';
import { PDF_MEDIUM_QUALITY } from './constants';
import {
    getIsLoadingContextMessage,
    getIsUpdatingContextMessage,
    handleClickDownloadBuild,
    handleClickDocumentItemBuild,
    handleClickElectricBillBuild,
    handleCloseDrawer,
} from './helpers';
import * as selectors from './selectors';

const Container = ({
    branding,
    deleteInteractiveProposalSignature,
    downloadDatasheet,
    downloadElectricBill,
    downloadFile,
    electricBills,
    fetchCommercialOffer,
    fetchElectricBill,
    fetchFinancingTemplate,
    fetchInteractiveProposalContent,
    fetchSmartDocumentContent,
    handleClickOpenApprovalModal,
    hasInteractiveProposalError,
    initialFetching,
    initTemplate,
    interactiveProposalData,
    interactiveProposalSignatureData,
    isApprovingInteractiveProposal,
    isDeletingInteractiveProposalSignature,
    isFetchingBranding,
    isFetchingCommercialOffer,
    isFetchingElectricBills,
    isFetchingFinancingTemplate,
    isFetchingInteractiveProposal,
    isFetchingInteractiveProposalContent,
    isFetchingInteractiveProposalSignature,
    isFetchingSmartDocumentContent,
    isFinancingMode,
    isSavingInteractiveProposalSignature,
    isStartingRequestFinancing,
    location,
    match,
    openFinancingModal,
    prepareRequest,
    proposalData,
    renderPdfFile,
    reset,
    resetTemplate,
    saveInteractiveProposalSignature,
    selectedTheme,
    setFinancingMode,
    setTheme,
    showToolbarMessage,
    toogleToolbarMessage,
}) => {
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';
    const [downloadFormat, setDownloadFormat] = useState(
        parseInt(localStorage.getItem('downloadFormat')) || 2
    );
    const [selectedDocument, setSelectedDocument] = useState({});
    const [showSignatureArea, setShowSignatureArea] = useState(false);
    const loadingContext = useContext(LoadingContext);
    const offerId = match.params.uid;
    const sideBarRef = useRef(null);
    const swipeableDrawerRef = useRef(null);

    const { search: queryParams = '' } = location;
    const { 'count-visit': countVisit } = parseQueryString(queryParams);

    useEffect(() => () => reset(), []);

    useEffect(() => {
        let _countVisit = true;
        if (countVisit === '0') _countVisit = false;
        initialFetching(offerId, initTemplate, _countVisit);
    }, [initTemplate, offerId]);

    useEffect(() => {
        getIsLoadingContextMessage({
            isFetchingBranding,
            isFetchingCommercialOffer,
            isFetchingElectricBills,
            isFetchingFinancingTemplate,
            isFetchingInteractiveProposal,
            isFetchingInteractiveProposalContent,
            isFetchingInteractiveProposalSignature,
            isFetchingSmartDocumentContent,
            isStartingRequestFinancing,
            loadingContext,
        });
    }, [
        isFetchingBranding,
        isFetchingCommercialOffer,
        isFetchingElectricBills,
        isFetchingFinancingTemplate,
        isFetchingInteractiveProposal,
        isFetchingInteractiveProposalContent,
        isFetchingInteractiveProposalSignature,
        isFetchingSmartDocumentContent,
        isStartingRequestFinancing,
    ]);

    useEffect(() => {
        getIsUpdatingContextMessage(
            isApprovingInteractiveProposal,
            isDeletingInteractiveProposalSignature,
            isSavingInteractiveProposalSignature,
            loadingContext
        );
    }, [
        isApprovingInteractiveProposal,
        isDeletingInteractiveProposalSignature,
        isSavingInteractiveProposalSignature,
    ]);

    const datasheets = get(interactiveProposalData, 'data_sheets', []);
    const hasApproved = get(interactiveProposalData, 'has_approbed', false);
    const hasPermalink = get(interactiveProposalData, 'has_permalink', false);
    const hasSignature = get(interactiveProposalData, 'has_signature', false);
    const smartDocuments = get(interactiveProposalData, 'smart_documments', []);

    useEffect(() => {
        if (!isEmpty(interactiveProposalData))
            setSelectedDocument({
                id: offerId,
                name: get(interactiveProposalData, 'proposal_name', ''),
                type: types.ONE_PROPOSAL_TYPE,
            });
    }, [interactiveProposalData]);

    const handleClickDatasheetItem = (datasheet) => {
        resetTemplate();
        setSelectedDocument(datasheet);
        setDownloadFormat(PDF_MEDIUM_QUALITY);
        handleCloseDrawer(swipeableDrawerRef);
    };

    const handleClickDownload = () =>
        handleClickDownloadBuild(
            downloadDatasheet,
            downloadElectricBill,
            downloadFile,
            loadingContext,
            renderPdfFile,
            selectedDocument,
            downloadFormat
        );

    const handleClickDocumentItem = (document) =>
        handleClickDocumentItemBuild(
            document,
            fetchFinancingTemplate,
            fetchInteractiveProposalContent,
            fetchSmartDocumentContent,
            initTemplate,
            isFinancingMode,
            resetTemplate,
            selectedDocument,
            setDownloadFormat,
            setFinancingMode,
            setSelectedDocument,
            swipeableDrawerRef
        );

    const handleClickElectricBillItem = (document) =>
        handleClickElectricBillBuild(
            document,
            fetchElectricBill,
            loadingContext,
            setDownloadFormat,
            setFinancingMode,
            setSelectedDocument,
            swipeableDrawerRef
        );

    const callback = () => {
        fetchCommercialOffer(offerId);
        openFinancingModal();
    };

    const handleClickAlliance = () => {
        if (proposalData?.has_financing_request) openFinancingModal();
        else prepareRequest(callback, offerId);
        handleCloseDrawer(swipeableDrawerRef);
    };

    const supportMenu = (
        <SupportMenuContent
            datasheets={datasheets}
            downloadFormat={downloadFormat}
            electricBills={electricBills}
            handleClickAlliance={handleClickAlliance}
            handleClickDatasheetItem={handleClickDatasheetItem}
            handleClickDocumentItem={handleClickDocumentItem}
            handleClickDownload={handleClickDownload}
            handleClickElectricBillItem={handleClickElectricBillItem}
            handleClickOpenApprovalModal={handleClickOpenApprovalModal}
            hasApproved={hasApproved}
            hasFinancingRequest={proposalData?.has_financing_request}
            hasSignature={hasSignature}
            interactiveProposalData={interactiveProposalData}
            interactiveProposalSignatureData={interactiveProposalSignatureData}
            isFetching={
                isFetchingBranding ||
                isFetchingElectricBills ||
                isFetchingFinancingTemplate ||
                isFetchingInteractiveProposal ||
                isFetchingInteractiveProposalContent ||
                isFetchingInteractiveProposalSignature ||
                isFetchingSmartDocumentContent
            }
            isFinancingMode={isFinancingMode}
            proposalId={offerId}
            selectedDocument={selectedDocument}
            setDownloadFormat={setDownloadFormat}
            smartDocuments={smartDocuments}
        />
    );

    const financingProps = {
        financing: get(interactiveProposalData, 'financing', {}),
        handleClickBack: () => {
            handleClickDocumentItem({
                id: offerId,
                name: get(interactiveProposalData, 'proposal_name', ''),
                type: types.ONE_PROPOSAL_TYPE,
            });
            setFinancingMode(false);
        },
        proposalData,
        offerId,
    };

    if (hasInteractiveProposalError || !hasPermalink)
        return <NotFound isLoading={isFetchingInteractiveProposal} />;

    return (
        <>
            <AppBarMessage
                showToolbarMessage={showToolbarMessage}
                toogleToolbarMessage={toogleToolbarMessage}
            />
            <Grid container spacing={0}>
                <Grid
                    item
                    lg={14}
                    md={12}
                    sx={{
                        height: 'calc(100vh - 60px)',
                    }}
                    xs={18}
                >
                    <ProposalResume
                        branding={branding}
                        handleClickAlliance={handleClickAlliance}
                        handleClickOpenApprovalModal={
                            handleClickOpenApprovalModal
                        }
                        hasApproved={hasApproved}
                        hasFinancingRequest={
                            proposalData?.has_financing_request
                        }
                        interactiveProposalData={interactiveProposalData}
                        isFinancingMode={isFinancingMode}
                        isLoading={isFetchingInteractiveProposal}
                        selectedTheme={selectedTheme}
                        setTheme={setTheme}
                    />
                    <PageWrapper
                        sx={{
                            height: {
                                md: 'calc(100% - 94px)',
                                xs: 'calc(100% - 130px)',
                            },
                            paddingTop: '24px',
                        }}
                    >
                        {isFinancingMode ? (
                            <FinancingContainer {...financingProps} />
                        ) : (
                            <>
                                <PreviewContent
                                    isFetchingFinancingTemplate={
                                        isFetchingFinancingTemplate
                                    }
                                    isFetchingInteractiveProposalContent={
                                        isFetchingInteractiveProposalContent
                                    }
                                    isFetchingSmartDocumentContent={
                                        isFetchingSmartDocumentContent
                                    }
                                    selectedDocument={selectedDocument}
                                />
                                <ContactFooter
                                    branding={branding}
                                    interactiveProposalData={
                                        interactiveProposalData
                                    }
                                />
                            </>
                        )}
                    </PageWrapper>
                </Grid>
                <StyledSideBarColumn
                    item
                    lg={4}
                    md={6}
                    ref={sideBarRef}
                    sx={{
                        backgroundColor: isDarkMode ? '#000' : '#fff',
                        borderLeft: isDarkMode
                            ? '1px solid rgba(145, 158, 171, 0.24)'
                            : '0',
                        display: { xs: 'none', md: 'block' },
                        height: 'calc(100vh - 60px)',
                    }}
                >
                    {supportMenu}
                </StyledSideBarColumn>
            </Grid>
            <SwipeableDrawer
                ref={swipeableDrawerRef}
                sxWrapper={{ height: 'calc(100vh - 100px)' }}
            >
                {supportMenu}
            </SwipeableDrawer>
            <ApprovalModal
                deleteInteractiveProposalSignature={
                    deleteInteractiveProposalSignature
                }
                hasApproved={hasApproved}
                hasSignature={hasSignature}
                interactiveProposalSignatureData={
                    interactiveProposalSignatureData
                }
                offerId={offerId}
                saveInteractiveProposalSignature={
                    saveInteractiveProposalSignature
                }
                setShowSignatureArea={setShowSignatureArea}
                showSignatureArea={showSignatureArea}
            />
            <FinancingModal {...financingProps} />
        </>
    );
};

Container.propTypes = {
    branding: PropTypes.object,
    deleteInteractiveProposalSignature: PropTypes.func,
    downloadDatasheet: PropTypes.func,
    downloadElectricBill: PropTypes.func,
    downloadFile: PropTypes.func,
    electricBills: PropTypes.array,
    fetchCommercialOffer: PropTypes.func,
    fetchElectricBill: PropTypes.func,
    fetchFinancingTemplate: PropTypes.func,
    fetchInteractiveProposalContent: PropTypes.func,
    fetchSmartDocumentContent: PropTypes.func,
    handleClickOpenApprovalModal: PropTypes.func,
    hasInteractiveProposalError: PropTypes.bool,
    initialFetching: PropTypes.func,
    initTemplate: PropTypes.func,
    interactiveProposalData: PropTypes.object,
    interactiveProposalSignatureData: PropTypes.object,
    isApprovingInteractiveProposal: PropTypes.bool,
    isDeletingInteractiveProposalSignature: PropTypes.bool,
    isFetchingBranding: PropTypes.bool,
    isFetchingCommercialOffer: PropTypes.bool,
    isFetchingElectricBills: PropTypes.bool,
    isFetchingFinancingTemplate: PropTypes.bool,
    isFetchingInteractiveProposal: PropTypes.bool,
    isFetchingInteractiveProposalContent: PropTypes.bool,
    isFetchingInteractiveProposalSignature: PropTypes.bool,
    isFetchingSmartDocumentContent: PropTypes.bool,
    isFinancingMode: PropTypes.bool,
    isSavingInteractiveProposalSignature: PropTypes.bool,
    isStartingRequestFinancing: PropTypes.bool,
    location: PropTypes.object,
    match: PropTypes.object,
    openFinancingModal: PropTypes.func,
    prepareRequest: PropTypes.func,
    proposalData: PropTypes.object,
    renderPdfFile: PropTypes.func,
    reset: PropTypes.func,
    resetTemplate: PropTypes.func,
    saveInteractiveProposalSignature: PropTypes.func,
    selectedTheme: PropTypes.string,
    setFinancingMode: PropTypes.func,
    setTheme: PropTypes.func,
    showToolbarMessage: PropTypes.bool,
    toogleToolbarMessage: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    branding: selectors.getBrandingConfig,
    electricBills: selectors.getElectricBillsData,
    hasInteractiveProposalError: selectors.getHasInteractiveProposalError,
    interactiveProposalData: selectors.getInteractiveProposalData,
    interactiveProposalSignatureData:
        selectors.getInteractiveProposalSignatureData,
    isApprovingInteractiveProposal: selectors.getIsApprovingInteractiveProposal,
    isDeletingInteractiveProposalSignature:
        selectors.getIsDeletingInteractiveProposalSignature,
    isFetchingBranding: selectors.getIsFetchingBranding,
    isFetchingCommercialOffer: selectors.getIsFetchingCommercialOffer,
    isFetchingElectricBills: selectors.getIsFetchingElectricBills,
    isFetchingFinancingTemplate: selectors.getIsFetchingFinancingTemplate,
    isFetchingInteractiveProposal: selectors.getIsFetchingInteractiveProposal,
    isFetchingInteractiveProposalContent:
        selectors.getIsFetchingInteractiveProposalContent,
    isFetchingInteractiveProposalSignature:
        selectors.getIsFetchingInteractiveProposalSignature,
    isFetchingSmartDocumentContent: selectors.getIsFetchingSmartDocumentContent,
    isFinancingMode: selectors.getIsFinancingMode,
    isStartingRequestFinancing: financingSelectors.isStartingRequestFinancing,
    isSavingInteractiveProposalSignature:
        selectors.getIsSavingInteractiveProposalSignature,
    selectedTheme: selectors.getTheme,
    showToolbarMessage: selectors.getShowToolbarMessage,
    proposalData: selectors.getCommercialOfferData,
});

const mapDispatchToProps = (dispatch) => ({
    deleteInteractiveProposalSignature: (offerId) =>
        dispatch(actions.deleteInteractiveProposalSignature(offerId)),
    downloadDatasheet: (datasheetName, openLoading, closeLoading) =>
        dispatch(
            actions.downloadDatasheet(datasheetName, openLoading, closeLoading)
        ),
    downloadElectricBill: (archive, fileName, loadingContext, name, urlType) =>
        dispatch(
            actions.downloadElectricBill(
                archive,
                fileName,
                loadingContext,
                name,
                urlType
            )
        ),
    downloadFile: (name, url, type) =>
        dispatch(actions.downloadFile(name, url, type)),
    fetchCommercialOffer: (id) =>
        dispatch(actions.fetchInteractiveProposal(id)),
    fetchElectricBill: (file_name, onSuccess) =>
        dispatch(lisaFileActions.getFile(file_name, onSuccess)),
    fetchFinancingTemplate: (id, initTemplate) =>
        dispatch(actions.fetchFinancingTemplateContent(id, initTemplate)),
    fetchInteractiveProposalContent: (id, initTemplate) =>
        dispatch(actions.fetchInteractiveProposalContent(id, initTemplate)),
    fetchSmartDocumentContent: (id, initTemplate) =>
        dispatch(actions.fetchSmartDocumentContent(id, initTemplate)),
    handleClickOpenApprovalModal: () =>
        dispatch(actions.openApprovalModal(true)),
    initialFetching: (offerId, initTemplate, countVisit) =>
        dispatch(actions.initialFetching(offerId, initTemplate, countVisit)),
    openFinancingModal: () => dispatch(financingActions.openModal(true)),
    prepareRequest: (callback, offerId) =>
        dispatch(financingActions.prepareRequest(callback, offerId)),
    renderPdfFile: (
        templateFinishedId,
        templateName,
        openLoading,
        closeLoading,
        templateType,
        downloadFormat
    ) =>
        dispatch(
            actions.renderPdfFile(
                templateFinishedId,
                templateName,
                openLoading,
                closeLoading,
                templateType,
                downloadFormat
            )
        ),
    reset: () => dispatch(actions.reset()),
    saveInteractiveProposalSignature: (offerId, values) =>
        dispatch(actions.saveInteractiveProposalSignature(offerId, values)),
    setFinancingMode: (value) => dispatch(actions.setFinancingMode(value)),
    setTheme: (value) => dispatch(actions.setTheme(value)),
    toogleToolbarMessage: (value) =>
        dispatch(actions.toogleToolbarMessage(value)),
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    withTemplateCore(() => ({
        application: 'sunpal',
        baseUrl: process.env.REACT_APP_API_URL,
        editionMode: editionLevels.NO_EDITION_MODE,
        froalaLicenseKey: process.env.REACT_APP_FROALA_LICENSE,
        googleApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
        language: getCurrentLanguage(),
    }))
)(Container);
