import { deleInteractiveProposalSignature } from 'common/api/interactiveProposal';

import {
    DELETE_INTERACTIVE_PROPOSAL_SIGNATURE,
    DELETE_INTERACTIVE_PROPOSAL_SIGNATURE_FAILURE,
    DELETE_INTERACTIVE_PROPOSAL_SIGNATURE_SUCCESS,
} from '../actionTypes';
import { interactiveProposalPreviewActions } from '../reducer';

import fetchInteractiveProposal from './fetchInteractiveProposal';
import fetchInteractiveProposalSignature from './fetchInteractiveProposalSignature';

export default (offerId) => (dispatch) => {
    dispatch(
        interactiveProposalPreviewActions[
            DELETE_INTERACTIVE_PROPOSAL_SIGNATURE
        ]()
    );

    deleInteractiveProposalSignature(offerId)
        .then(() => {
            dispatch(
                interactiveProposalPreviewActions[
                    DELETE_INTERACTIVE_PROPOSAL_SIGNATURE_SUCCESS
                ]()
            );
            dispatch(fetchInteractiveProposal(offerId));
            dispatch(fetchInteractiveProposalSignature(offerId));
        })
        .catch((error) =>
            dispatch(
                interactiveProposalPreviewActions[
                    DELETE_INTERACTIVE_PROPOSAL_SIGNATURE_FAILURE
                ](error?.response?.data?.errors)
            )
        );
};
