import { createSlice } from '@reduxjs/toolkit';

import {
    FETCH_DATA,
    FETCH_DATA_FAILURE,
    FETCH_DATA_SUCCESS,
    INITIALIZE_FORM,
    RESET,
    RESET_FORM,
    SAVE,
    SAVE_FAILURE,
    SAVE_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    fetch: { data: {}, errors: null, isFetching: false },
    initialValues: {
        city: '',
        email: '',
        first_last_name: '',
        first_name: '',
        rfc: '',
        second_last_name: '',
        second_name: '',
        state: '',
        street: '',
        suburb: '',
        zipcode: '',
    },
    save: {
        data: null,
        errors: null,
        isSaving: false,
    },
};

const creditBureauCardSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_DATA]: (state) => {
            state.fetch = {
                ...state.fetch,
                data: {},
                errors: null,
                isFetching: true,
            };
        },
        [FETCH_DATA_FAILURE]: (state, action) => {
            state.fetch = {
                ...state.fetch,
                data: {},
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_DATA_SUCCESS]: (state, action) => {
            state.fetch = {
                ...state.fetch,
                data: action.payload,
                errors: null,
                isFetching: false,
            };
        },
        [INITIALIZE_FORM]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [RESET]: () => INITIAL_STATE,
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
        },
        [SAVE]: (state) => {
            state.save = {
                ...state.save,
                data: null,
                errors: null,
                isSaving: true,
            };
        },
        [SAVE_FAILURE]: (state, action) => {
            state.save = {
                ...state.save,
                data: null,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_SUCCESS]: (state, action) => {
            state.save = {
                ...state.save,
                data: action.payload,
                errors: null,
                isSaving: false,
            };
        },
    },
});

export const creditBureauCardActions = creditBureauCardSlice.actions;

export default creditBureauCardSlice.reducer;
