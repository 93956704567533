import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getCreditDocuments = createSelector(
    getModel,
    (model) => model.creditDocuments
);

export const getIsFetchingCreditDocuments = createSelector(
    getCreditDocuments,
    (values) => values.isFetching
);

export const getCreditDocumentsData = createSelector(
    getCreditDocuments,
    (values) => values.data
);

export const getDocuments = createSelector(getCreditDocumentsData, (model) =>
    model.filter((item) => item.type === 1)
);

export const getApplicationDocuments = createSelector(
    getCreditDocumentsData,
    (model) => model.filter((item) => item.type === 0)
);

export const getCreditDocumentsErrors = createSelector(
    getCreditDocuments,
    (creditDocuments) => creditDocuments.errors
);

export const getRequestFinancing = createSelector(
    getModel,
    (model) => model.requestFinancing
);

export const getIsOpenModal = createSelector(
    getModel,
    (model) => model.isOpenModal
);

export const isStartingRequestFinancing = createSelector(
    getRequestFinancing,
    (values) => values.isStarting
);

export const getSelectedView = createSelector(
    getModel,
    (model) => model.selectedView
);
