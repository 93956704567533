import { getCommercialOffer } from 'common/api/v1/sunpay';

import {
    FETCH_COMMERCIAL_OFFER,
    FETCH_COMMERCIAL_OFFER_FAILURE,
    FETCH_COMMERCIAL_OFFER_SUCCESS,
} from '../actionTypes';
import { interactiveProposalPreviewActions } from '../reducer';

export default (id) => (dispatch) =>
    new Promise((resolve) => {
        dispatch(interactiveProposalPreviewActions[FETCH_COMMERCIAL_OFFER]());

        getCommercialOffer(id)
            .then((response) =>
                dispatch(
                    interactiveProposalPreviewActions[
                        FETCH_COMMERCIAL_OFFER_SUCCESS
                    ](response?.data)
                )
            )
            .catch((error) =>
                dispatch(
                    interactiveProposalPreviewActions[
                        FETCH_COMMERCIAL_OFFER_FAILURE
                    ](error?.response?.data?.errors)
                )
            )
            .finally(resolve);
    });
