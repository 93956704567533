import React from 'react';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useTheme } from '@mui/material/styles';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Box,
    Typography,
} from 'sunwise-ui';

import { handleFileURL, stringAvatar } from 'common/utils/helpers';

const InstallerCard = ({ agent, expanded, handleChange }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';

    if (isEmpty(agent)) return null;

    const agentName = `${get(agent, 'first_name', '')} ${get(
        agent,
        'last_name',
        ''
    )}`;

    return (
        <Box sx={{ mt: 'auto', px: 1 }}>
            <Accordion
                expanded={expanded}
                onChange={handleChange}
                sx={{ backgroundColor: isDarkMode ? '#1b1c20' : '#eef4fa' }}
            >
                <AccordionSummary
                    expandIcon={<ExpandLessIcon />}
                    aria-controls="contact-content"
                    id="contact-header"
                >
                    <Typography fontWeight="bold" variant="body2">
                        {agentName}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box alignItems="center" display="flex" gap={2}>
                        {get(agent, 'profile_image', '') ? (
                            <Avatar
                                alt={agentName}
                                src={handleFileURL(
                                    agent?.profile_image,
                                    process.env.REACT_APP_S3_MEDIA_PATH
                                )}
                                sx={{ height: 60, width: 60 }}
                            />
                        ) : (
                            <Avatar
                                {...stringAvatar(agentName)}
                                sx={{
                                    fontSize: '16px',
                                    height: 60,
                                    width: 60,
                                }}
                            />
                        )}
                        <Box display="flex" flexDirection="column">
                            <Typography variant="body2">
                                {t('If you have any questions, please contact')}
                            </Typography>
                            <Typography variant="caption">
                                {`${
                                    agent.phone
                                        ? agent.phone
                                        : t('Unregistered phone')
                                } / ${agent.email}`}
                            </Typography>
                        </Box>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

InstallerCard.propTypes = {
    agent: PropTypes.object,
    expanded: PropTypes.bool,
    handleChange: PropTypes.func,
};

export default InstallerCard;
