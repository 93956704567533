import { reject } from 'common/api/v1/burocredito';
import alerts from 'common/modules/alerts';

import { AUTH, AUTH_SUCCESS, AUTH_FAILURE } from '../actionTypes';
import { BUREAU_STEPS } from '../constants';
import { creditBureauActions } from '../reducer';

import setCurrentStep from './setCurrentStep';

export default (token) => (dispatch) => {
    dispatch(creditBureauActions[AUTH]());

    reject(token)
        .then((response) => {
            dispatch(creditBureauActions[AUTH_SUCCESS](response.data));
            dispatch(setCurrentStep(BUREAU_STEPS.COMPLETE));
            dispatch(alerts.actions.close());
        })
        .catch((error) =>
            dispatch(
                creditBureauActions[AUTH_FAILURE](error?.response?.data?.errors)
            )
        );
};
