import React from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Alert, Box, Grid, Typography } from 'sunwise-ui';

import { getFormattedDate } from '../helpers';

const ApprovalData = ({ approveEmail, hasApproved, onClick, updatedAt }) => {
    const { t } = useTranslation();

    if (!hasApproved) return;

    return (
        <Grid container mb={2}>
            <Grid item xs={18}>
                <Alert
                    icon={<CheckCircleIcon sx={{ color: '#3caf5f' }} />}
                    onClick={onClick}
                    severity="success"
                    sx={{ mb: 0, cursor: 'pointer' }}
                >
                    <Typography variant="body2" fontWeight="bold">
                        {t('You have approved this proposal')}
                    </Typography>
                    <Box
                        flexDirection="column"
                        sx={{ display: { md: 'flex', xs: 'none' } }}
                    >
                        <Typography variant="caption">
                            {`${t('Approved on')} ${getFormattedDate(
                                updatedAt
                            )}`}
                        </Typography>
                        <Typography variant="caption">
                            {`${t('Sent to')} ${approveEmail}`}
                        </Typography>
                    </Box>
                </Alert>
            </Grid>
        </Grid>
    );
};

ApprovalData.propTypes = {
    approveEmail: PropTypes.string,
    hasApproved: PropTypes.bool,
    onClick: PropTypes.func,
    updatedAt: PropTypes.string,
};

export default ApprovalData;
